import { json2xml } from 'xml-js';
import { ISetting } from 'src/models/setting.model';
import ENV from 'src/constants/env.constants';
import {
  ISharepointProperty,
  IMccListData,
  IUploadData,
  ICreateFolderData,
  IDeleteDocumentData,
} from 'src/models/sharepoint-service.model';
import {
  SharePointApplicationType,
  SharePointDownloadType,
} from 'src/constants/sharepoint-common.constant';
import moment from 'moment';

export const SharepointEnvironmentProperties = {
  SHAREPOINT_REQUEST_HOST: `host:${ENV.VITE_SHAREPOINT_HOST}` || '',
  SHAREPOINT_BASE_URL: ENV.VITE_SHAREPOINT_API_BASE_URL || '',
  SHAREPOINT_LAYER7_BASE_URL: ENV.VITE_SHAREPOINT_LAYER7_API_BASE_URL || '',
  SHAREPOINT_CREATE_FOLDER_ACTION:
    `action="${ENV.VITE_SHAREPOINT_CREATE_FOLDER_ACTION}"` || '',
  SHAREPOINT_COPY_INTO_ITEMS_ACTION:
    `action="${ENV.VITE_SHAREPOINT_COPY_INTO_ITEMS_ACTION}"` || '',
  SHAREPOINT_DELETE_DOCUMENT_ACTION:
    `action="${ENV.VITE_SHAREPOINT_DELETE_DOCUMENT_ACTION}"` || '',
  SHAREPOINT_GET_LIST_ITEMS_ACTION:
    `action="${ENV.VITE_SHAREPOINT_GET_LIST_ITEMS_ACTION}"` || '',
};

export const requestHeaderProperty = {
  sharepointRequestContentType: 'application/soap+xml;charset=UTF-8;',
  xmlnsAttribute: 'http://schemas.microsoft.com/sharepoint/soap/',
};

export const getFileDataUrl = async (file: File, trim = true) => {
  const result = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        trim
          ? JSON.stringify(reader.result).split(',')[1].replace(/"/g, '')
          : reader.result
      );
    reader.onerror = (error) => reject(error);
  });

  return result;
};

export const getSharepointEnvironmentProperty = async (
  settings: ISetting[],
  applicationType
) => {
  const result = new Promise<ISharepointProperty>((resolve) => {
    let libraryName = '';
    let libraryId = '';
    settings.map((setting) => {
      if (
        setting.category?.toLowerCase().trim() ===
        SharePointDownloadType.MCC_SHAREPOINT_TYPE.toLowerCase()
      ) {
        if (setting.name.includes(applicationType)) {
          const isSharepointGuid = setting.name.includes('GUID') ?? false;
          const isSharepointName = setting.name.includes('NAME') ?? false;
          if (isSharepointGuid) {
            libraryId = setting.value || '';
          }
          if (isSharepointName) {
            libraryName = setting.value || '';
          }
        }
      }
    });
    const sharepointSetting: ISharepointProperty = {
      dbNameEquivalent: applicationType || '',
      documentLibraryName: libraryName,
      documentLibraryId: libraryId,
    };
    resolve(sharepointSetting);
  });
  return result;
};

const formatNumber = (numberToFormat) => {
  return numberToFormat.toString().replace(/(\d{3})(?=(\d{3})+(?!\d))/g, '$1,');
};

const padWithZeroes = (numberToPad, length) => {
  let requestId = JSON.stringify(numberToPad);
  while (requestId.length < length) {
    requestId = `0${requestId}`;
  }
  return requestId;
};

export const getParentFolderName = (ticketId) => {
  const quotient = Math.trunc(ticketId / 5000);
  const startRange = 5000 * quotient;
  const endRange = startRange + 4999;
  return `${formatNumber(padWithZeroes(startRange, 6))}-${formatNumber(
    padWithZeroes(endRange, 6)
  )}`;
};

export const getSharePointSettingProperty = async (
  applicationType: SharePointApplicationType,
  setting
) => {
  let response: ISharepointProperty = {} as any;
  switch (applicationType) {
    case SharePointApplicationType.LER_TYPE: {
      const settingData = await getSharepointEnvironmentProperty(
        setting.list,
        SharePointApplicationType.LER_TYPE
      );
      response = settingData;
      break;
    }
    case SharePointApplicationType.KEY_REQUEST_TYPE: {
      const settingData = await getSharepointEnvironmentProperty(
        setting.list,
        SharePointApplicationType.KEY_REQUEST_TYPE
      );
      response = settingData;
      break;
    }
    case SharePointApplicationType.SUBSTATION_ENTRY_TYPE: {
      const settingData = await getSharepointEnvironmentProperty(
        setting.list,
        SharePointApplicationType.SUBSTATION_ENTRY_TYPE
      );
      response = settingData;
      break;
    }
    case SharePointApplicationType.TROUBLE_TICKET_TYPE: {
      const settingData = await getSharepointEnvironmentProperty(
        setting.list,
        SharePointApplicationType.TROUBLE_TICKET_TYPE
      );
      response = settingData;
      break;
    }
    default:
      break;
  }
  return response;
};

export const checkCachedTokenDuration = (
  timeLastFetched: Date,
  latestTime: Date
) => {
  let minuteDuration = 0;
  if (timeLastFetched && latestTime) {
    const lastTimeFetched = moment(timeLastFetched);
    const latestTimeFetched = moment(latestTime);
    const duration = moment.duration(latestTimeFetched.diff(lastTimeFetched));
    minuteDuration = duration.asMinutes();
  }
  return minuteDuration;
};

export const isTokenMatched = (oldToken: any, newToken: any) => {
  let tokenMatched = false;
  if (oldToken && newToken) {
    if (oldToken.trim() === newToken.trim()) {
      tokenMatched = true;
    }
  }
  return tokenMatched;
};

const helpers = {
  mccListDataHelper: (
    parentFolder,
    sourceFolder,
    token,
    documentLib,
    documentId
  ) => {
    const data: IMccListData = {
      xmlns: requestHeaderProperty.xmlnsAttribute,
      listName: documentId,
      baseUrl: `${SharepointEnvironmentProperties.SHAREPOINT_BASE_URL}/mcc/ler`,
      documentLibrary: documentLib,
      parentDirectory: `${parentFolder.length ? `${parentFolder}/` : ''}`,
      sourceDirectory: sourceFolder,
      accessToken: token,
      includeMandatoryColumns: 'FALSE',
      includeAttachmentUrls: 'TRUE',
      attributeName: 'Title',
      attributeNameId: 'ID',
      dateInUtc: 'FALSE',
      rowLimit: '150',
      webID: '',
    };
    return data;
  },
  uploadFileDataHelper: (
    parentFolder,
    stream,
    documentName,
    sharepointToken,
    requestCreatedBy,
    sourceFolderName,
    documentLibraryName
  ) => {
    const data: IUploadData = {
      xmlns: requestHeaderProperty.xmlnsAttribute,
      createdBy: `Created by ${requestCreatedBy}`,
      documentLibrary: documentLibraryName,
      parentDirectory: `${parentFolder.length ? `${parentFolder}/` : ''}`,
      sourceFolder: sourceFolderName,
      accessToken: sharepointToken,
      fileName: documentName,
      fileStream: stream,
      displayName: 'Title',
      internalName: 'Title',
      type: 'Text',
    };
    return data;
  },
  createFolderDataHelper: (parentFolder, directoryName, documentLib, token) => {
    const data: ICreateFolderData = {
      xmlns: `${requestHeaderProperty.xmlnsAttribute}dws/`,
      parentDirectory: `${parentFolder.length ? `${parentFolder}/` : ''}`,
      folderName: directoryName,
      accessToken: token,
      documentLibrary: documentLib,
    };
    return data;
  },
  deleteDocumentDataHelper: (
    parentFolder,
    directoryName,
    fileId,
    listNameVal,
    documentLibraryName,
    fileName,
    token
  ) => {
    const data: IDeleteDocumentData = {
      cmd: 'Delete',
      fieldNameId: 'ID',
      fieldNameRef: 'FileRef',
      id: '1',
      listName: listNameVal,
      onError: 'Continue',
      xmlns: requestHeaderProperty.xmlnsAttribute,
      fieldNameIdValue: fileId,
      fieldNameRefValue: `mcc/ler/${documentLibraryName}/${
        parentFolder.length ? `${parentFolder}/` : ''
      }${directoryName}/${fileName}`,
      accessToken: token,
    };
    return data;
  },
  mccListXmlRequestBuilder: (data: IMccListData) => {
    const mccListRequest = {
      GetListItems: {
        _attributes: {
          xmlns: data.xmlns,
        },
        listName: data.listName,
        viewName: '',
        viewFields: {
          ViewFields: {
            FieldRef: [
              {
                _attributes: {
                  Name: data.attributeName,
                },
              },
              {
                _attributes: {
                  Name: data.attributeNameId,
                },
              },
            ],
          },
        },
        rowLimit: data.rowLimit,
        queryOptions: {
          QueryOptions: {
            Folder: `${data.baseUrl}/${data.documentLibrary}/${data.parentDirectory}${data.sourceDirectory}/`,
            IncludeMandatoryColumns: data.includeMandatoryColumns,
            IncludeAttachmentUrls: data.includeAttachmentUrls,
            DateInUtc: data.dateInUtc,
          },
        },
        webID: '',
      },
    };
    return json2xml(JSON.stringify(mccListRequest), { compact: true });
  },
  createFolderXmlRequestBuilder: (data: ICreateFolderData) => {
    const createFolderRequest = {
      CreateFolder: {
        _attributes: {
          xmlns: data.xmlns,
        },
        url: `${data.documentLibrary}/${data.parentDirectory}${data.folderName}`,
      },
    };
    return json2xml(JSON.stringify(createFolderRequest), { compact: true });
  },
  fileUploadXmlRequestBuilder: (data: IUploadData) => {
    const uploadFileRequest = {
      CopyIntoItems: {
        _attributes: {
          xmlns: data.xmlns,
        },
        SourceUrl: `${data.fileName}`,
        DestinationUrls: {
          string: `${SharepointEnvironmentProperties.SHAREPOINT_BASE_URL}/mcc/ler/${data.documentLibrary}/${data.parentDirectory}${data.sourceFolder}/${data.fileName}`,
        },
        Fields: {
          FieldInformation: {
            _attributes: {
              Type: data.type,
              DisplayName: data.displayName,
              InternalName: data.internalName,
              Value: data.createdBy,
            },
          },
        },
        Stream: data.fileStream,
      },
    };
    return json2xml(JSON.stringify(uploadFileRequest), { compact: true });
  },
  deleteDocumentXmlRequestBuilder: (data: IDeleteDocumentData) => {
    const updateListItemsRequest = {
      UpdateListItems: {
        _attributes: {
          xmlns: data.xmlns,
        },
        listName: data.listName,
        updates: {
          Batch: {
            _attributes: {
              OnError: data.onError,
            },
            Method: {
              _attributes: {
                Cmd: data.cmd,
                ID: data.id,
              },
              Field: [
                {
                  _attributes: {
                    Name: data.fieldNameId,
                  },
                  _text: data.fieldNameIdValue,
                },
                {
                  _attributes: {
                    Name: data.fieldNameRef,
                  },
                  _text: data.fieldNameRefValue,
                },
              ],
            },
          },
        },
      },
    };
    return json2xml(JSON.stringify(updateListItemsRequest), { compact: true });
  },
  fileUploadSuccessValidation: (response) => {
    let status = false;
    try {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response, 'text/xml');
      let copyResult: any = '';
      for (
        let i = 0, a = xml.getElementsByTagName('CopyResult');
        i < xml.getElementsByTagName('CopyResult').length;
        i++
      ) {
        copyResult = a[i].getAttribute('ErrorCode');
      }
      if (copyResult === 'Success') {
        status = true;
      }
    } catch (error) {
      status = false;
    }
    return status;
  },
  deleteDocumentSuccessValidation: (response) => {
    let status = false;
    try {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response, 'text/xml');
      const deleteDocumentResult: any = xml.getElementsByTagName('ErrorCode');
      for (let x = 0; x < deleteDocumentResult.length; x++) {
        const result = deleteDocumentResult[x].childNodes;
        if (result.length) {
          if (result[x].nodeValue.includes('0x00000000')) {
            status = true;
          }
        }
      }
    } catch (error) {
      status = false;
    }
    return status;
  },
  createFolderSuccessValidation: (response) => {
    let status = false;
    try {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response, 'text/xml');
      const createFolderResult: any =
        xml.getElementsByTagName('CreateFolderResult');
      for (let x = 0; x < createFolderResult.length; x++) {
        const result = createFolderResult[x].childNodes;
        if (result.length) {
          if (result[x].nodeValue.search('Result')) {
            status = true;
          }
          if (result[x].nodeValue.includes('AlreadyExists')) {
            status = true;
          }
        }
      }
    } catch (error) {
      status = false;
    }
    return status;
  },
};

export { helpers };
