import React, { useCallback, useState } from 'react';
import {
  ATMDropdown,
  ATMRadio,
} from 'shared-it-appmod-ui';
import {
  IOutageDateFilter,
} from 'src/models/calendar-outage.model';
import { SortByOptions } from './outage-calendar-filters.component';
import styles from '../outage-calendar.module.scss';

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OutageDateSortType {
  Generator = 'Generator',
  Scheduled = 'Scheduled',
}

export enum SortByThenBy {
  ThenBy = 'Then By',
  SortBy = 'Sort By',
}

export type IProps = {
  name: any;
  label: any;
  defaultValue?: any;
  filters?: IOutageDateFilter;
  setFilters: React.Dispatch<React.SetStateAction<IOutageDateFilter>>;
};

export const getSortThenBy = (nameVal) => {
  const sortThenBy = nameVal.includes('thenBy')
    ? SortByThenBy.ThenBy
    : SortByThenBy.SortBy;
  return sortThenBy;
};

export type OrderByValue = {
  Scheduled: any;
  Generator: any;
};

export const getOrderByValue = (data: IOutageDateFilter) => {
  let orderBy: any = '';
  if (data) {
    if (data.sortListBy) {
      orderBy = data.sortListBy?.sortBy?.by === SortByOptions.FacilityName ? data.sortListBy?.sortBy?.orderBy : data.sortListBy?.thenBy?.orderBy;
    }
  }

return orderBy;
};

const OutageCalendarSortByOptions: React.FC<IProps> = ({
  name,
  label,
  filters,
  defaultValue,
  setFilters,
}) => {
  const getFilterValue = useCallback(
    (nameVal) => {
      let by: any = '';
      const sortThenBy = getSortThenBy(nameVal);

      switch (sortThenBy) {
        case SortByThenBy.SortBy: {
          by = filters?.sortListBy?.sortBy?.by;
          break;
        }
        case SortByThenBy.ThenBy: {
          by = filters?.sortListBy?.thenBy?.by;
          break;
        }
      }

      return by;
    },
    [filters]
  );

  const getDefaultValues = useCallback(
    (nameValue, isRadio: boolean) => {
      let value: any = '';
      const sortThenBy = getSortThenBy(nameValue);

      switch (sortThenBy) {
        case SortByThenBy.SortBy: {
          value = isRadio
            ? filters?.sortListBy?.sortBy?.orderBy
            : filters?.sortListBy?.sortBy?.by;
          break;
        }
        case SortByThenBy.ThenBy: {
          value = isRadio
            ? filters?.sortListBy?.thenBy?.orderBy
            : filters?.sortListBy?.thenBy?.by;
          break;
        }
      }
      return value;
    },
    [filters]
  );

  const [defaultVal, setDefaultVal] = useState(getDefaultValues(name, false));
  const [orderBy, setOrderBy] = useState(getDefaultValues(name, true));
  const [ascending, setAscending] = useState(
    getDefaultValues(name, true) === 'asc' ?? false
  );
  const [descending, setDescending] = useState(
    getDefaultValues(name, true) === 'desc' ?? false
  );

  const setOutageDateSortFilters = useCallback(
    (nameValue: any, selectedValue, order, isRadio: boolean) => {
      const sortThenBy = getSortThenBy(nameValue);
      switch (sortThenBy) {
        case SortByThenBy.SortBy: {
          setFilters((values) => ({
            ...values,
            sortListBy: {
              sortBy: {
                by: isRadio ? values?.sortListBy?.sortBy?.by : selectedValue,
                orderBy: order,
              },
              thenBy: {
                by: values?.sortListBy?.thenBy?.by,
                orderBy: values?.sortListBy?.thenBy?.orderBy,
              },
            },
          }));
          break;
        }
        case SortByThenBy.ThenBy: {
          setFilters((values) => ({
            ...values,
            sortListBy: {
              sortBy: {
                by: values?.sortListBy?.sortBy?.by,
                orderBy: values?.sortListBy?.sortBy?.orderBy,
              },
              thenBy: {
                by: isRadio ? values?.sortListBy?.thenBy?.by : selectedValue,
                orderBy: order,
              },
            },
          }));
          break;
        }
      }
    },
    [filters, setFilters]
  );

  return (
    <>
    <div className={styles.sortByDiv}>
    <label className={styles.sortByLbl}>{label}</label>
      <ATMDropdown
        size="small"
        selection
        name={name}
        selectOnBlur={false}
        placeholder="Select"
        value={defaultVal ?? defaultValue}
        clearable
        options={Object.values(SortByOptions)
          .filter((val) => typeof val === 'string')
          .map((value, key) => ({
            key,
            value,
            text: value,
          }))}
        onChange={(_, { value }) => {
          setOutageDateSortFilters(name, value, orderBy, false);
          setDefaultVal(value);
          return value;
        }}
      />
      <span className={styles.sortRadioOptions}>
      <ATMRadio
        name={name}
        label="Ascending"
        checked={ascending}
        onClick={() => {
            setDescending(false);
            setAscending(true);
            setOrderBy('asc');
            const val = getFilterValue(name);
            setOutageDateSortFilters(name, val, 'asc', true);
            setDefaultVal(getDefaultValues(name, false));
          }}
        value="asc"
      />
        <ATMRadio
          name={name}
          label="Descending"
          checked={descending}
          value="desc"
          onClick={() => {
            setAscending(false);
            setDescending(true);
            setOrderBy('desc');
            const val = getFilterValue(name);
            setOutageDateSortFilters(name, val, 'desc', true);
            setDefaultVal(getDefaultValues(name, false));
          }}
        />
        </span>
      </div>
    </>
  );
};

export default OutageCalendarSortByOptions;
