import React, { useState, useEffect } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  ATMSelect,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { useEntryReasonContext } from 'src/contexts/entry-reason.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import Lang from 'src/libraries/language';
import {
  ISubstationEntryAddForm,
  SubstationEntryAddFormSchema,
} from 'src/models/substation-entry.model';
import { IAuthorizedSubstation } from 'src/models/authorized-list.model';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { entryReasonActionTypes } from 'src/ducks/entry-reason.duck';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { getEntryReasonStatus } from 'src/selectors/entry-reason.selector';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { getEmployeeName } from 'src/helpers/employee.helper';
import { useFileContext } from 'src/contexts/file.context';
import { orderBy } from 'lodash';
import style from './substation-entry-log-add.module.scss';
import SubstationEntryLogDocumentsDetail from '../substation-entry-log-document/substation-entry-log-document-details.component';

type IProps = {
  loading: boolean;
  authLoading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  employees?: IAuthorizedSubstation[];
  handleClick: () => void;
  handleSubmit: (data: ISubstationEntryAddForm) => void;
};

type IFormContent = {
  employees?: IAuthorizedSubstation[];
  authLoading: boolean;
};

const FormContent: React.FC<IFormContent> = ({
  employees = [],
  authLoading,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useATMFormContext<ISubstationEntryAddForm>();
  const { state, actions } = useSubstationContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { state: entryReasonState, actions: entryReasonActions } =
    useEntryReasonContext();

  useEffect(() => {
    if (fileState.filename_list?.length || fileState.stash.length) {
      fileAction.deleteAllStashedFiles();
    }
  }, [employees]);

  useEffect(() => {
    entryReasonActions?.listGET();
    actions.listGET({ limit: 0, page: 1 });
  }, [entryReasonActions, actions]);

  const subLoading = getSubstationStatus(
    state,
    substationActionTypes.SUBSTATION_LIST_READ
  ).fetching;
  const entLoading = getEntryReasonStatus(
    entryReasonState,
    entryReasonActionTypes.ENTRY_REASON_LIST_READ
  ).fetching;

  return (
    <ATMGrid>
      <ATMGrid.Row columns={5} style={{ marginTop: '0.5rem' }}>
        <ATMGrid.Column>
          <ATMField
            key={`emp_${employees.length}`}
            as={ATMDropdown}
            name="empId"
            control={control}
            onChange={([_, { value }]) => value}
            error={errors.empId}
            placeholder={Lang.LBL_NAME}
            options={orderBy(
              employees.map((value) => ({
                key: value.employee.empId,
                value: value.employee.empId,
                text: value?.employee.contracShortNm
                  ? `${getEmployeeName(value.employee, false)} / ${
                      value.employee.contracShortNm
                    }`
                  : getEmployeeName(value.employee),
              })),
              'text',
              'asc'
            )}
            search
            selection
            clearable
            selectOnBlur={false}
            disabled={!employees.length && authLoading}
            loading={!employees.length && authLoading}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: 0 }}>
          <ATMField
            name="substationId"
            placeholder="Substation"
            as={ATMSelect}
            control={control}
            error={errors.substationId}
            style={{ display: 'block', marginRight: '1em' }}
            options={state.list.map((val) => {
              return {
                value: val.substationId,
                text: val.name,
                key: val.substationId,
              };
            })}
            onChange={([_, { value }]) => value}
            search
            selection
            clearable
            selectOnBlur={false}
            disabled={!state.list.length && subLoading}
            loading={!state.list.length && subLoading}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: 0 }}>
          <ATMField
            key={`entryReasonId_${entryReasonState.list.length}`}
            name="entryReasonId"
            placeholder="Activity"
            as={ATMSelect}
            control={control}
            error={errors.entryReasonId}
            style={{ display: 'block', marginRight: '1em' }}
            options={entryReasonState.list.map((val) => {
              return {
                value: val.entryReasonId,
                text: val.description,
                key: val.entryReasonId,
              };
            })}
            onChange={([_, { value }]) => value}
            search
            selection
            clearable
            selectOnBlur={false}
            disabled={!entryReasonState.list.length && entLoading}
            loading={!entryReasonState.list.length && entLoading}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: 0 }}>
          <ATMField
            placeholder="Purpose"
            as={ATMInput}
            name="comment"
            control={control}
            error={errors.comment}
            maxLength={40}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: 0 }}>
          <ATMField
            name="status"
            placeholder="Status"
            as={ATMSelect}
            control={control}
            error={errors.status}
            style={{ display: 'block', marginRight: '1em' }}
            defaultValue="In"
            options={[
              {
                key: 'in',
                value: 'In',
                text: 'In',
              },
              {
                key: 'both',
                value: 'Out',
                text: 'Both',
              },
            ]}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns={2} style={{ paddingTop: 0, paddingLeft: 0 }}>
        <ATMGrid.Column className={style.visitors} style={{ maxWidth: '40%' }}>
          <ATMField
            as={ATMTextArea}
            name="visitors"
            size="large"
            placeholder={Lang.LBL_SUBSTATION_VISITORS}
            control={control}
            maxLength={300}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ paddingLeft: 0, float: 'left' }}>
          <div style={{ marginTop: 27.5 }}>
            <FileUploader
              text="Upload Visitor List"
              icon="upload"
              displayRemoveButton={false}
              errorMessage="Failed to cache file(s)"
              successMessage="Your file(s) has been cached, but will not be uploaded until the Substation Entry is created"
              onSuccess={() => setValue('fileAttachedFlag', true)}
            />
            <div style={{ marginTop: 5 }}>
              <SubstationEntryLogDocumentsDetail
                enableRemoveButton
                onDelete={(data) => {
                  if (data.length === 1) {
                    setValue('fileAttachedFlag', false);
                  }
                }}
              />
            </div>
          </div>
        </ATMGrid.Column>
        <ATMGrid.Column />
        <ATMGrid.Column />
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const SubstationEntryLogAddView: React.FC<IProps> = ({
  formRef,
  authLoading,
  employees,
  loading,
  handleClick,
  handleSubmit,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <div className={style.wrapper}>
      <ATMForm
        ref={formRef}
        defaultValues={
          {
            entryReasonId: 5,
            fileAttachedFlag: false,
          } as Partial<ISubstationEntryAddForm>
        }
        onSubmit={(formData, { reset }) => {
          handleSubmit(formData);

          reset({
            empId: '',
            substationId: '',
            entryReasonId: 5,
            comment: '',
            status: 'In',
            visitors: '',
          });
        }}
        mode="onChange"
        validationSchema={SubstationEntryAddFormSchema}
      >
        {(props) => {
          const { formState } = props;

          setIsDirty(
            (formState.dirtyFields.empId &&
              formState.dirtyFields.substationId) ||
              false
          );

          return (
            <ATMFormProvider {...props}>
              <FormContent employees={employees} authLoading={authLoading} />
            </ATMFormProvider>
          );
        }}
      </ATMForm>

      <ATMGrid.Row style={{ marginTop: '1em' }}>
        <ATMButton
          primary
          content="Add Entry"
          disabled={!isDirty || loading}
          loading={loading}
          onClick={() => {
            handleClick();
          }}
          id="add"
        />
      </ATMGrid.Row>
    </div>
  );
};

export default SubstationEntryLogAddView;
