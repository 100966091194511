import { httpClient } from 'src/libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import Moment from 'src/libraries/moment.library';
import {
  IAuthorizedListForm,
  AuthorizedListListPayloadSchema,
  AuthorizedListDeletePayloadSchema,
  AuthorizedListSchema,
  AuthorizedUserDetailsSchema,
  AuthorizedExpiredListPayload,
  AuthorizedSubstationPayloadSchema,
  AuthorizedSuspensionHistoryPayloadSchema,
  AuthorizedSuspensionHistoryAddPayloadSchema,
  AuthorizedListEmployeePayloadSchema,
} from 'src/models/authorized-list.model';

const client = httpClient();
const endpoint = 'auth-list';
const suspensionEndpoint = 'suspension-list-history';

const services = {
  dataGET: async (id?: string) => {
    return client.get(`/${endpoint}/${id}`, {}, AuthorizedListSchema);
  },

  empDataGET: async (value?: string) => {
    return client.get(
      `/${endpoint}`,
      {
        action: 'findByEmp',
        filters: [
          {
            name: 'empId',
            value,
          },
        ],
      },
      AuthorizedListSchema
    );
  },

  dataByEmpId: async (empId?: string) => {
    const params = {
      filters: [{ name: 'empId', value: empId }],
    };
    return client.get(`/${endpoint}`, params, AuthorizedListListPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };

    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, AuthorizedListListPayloadSchema);
  },

  listUserGET: async (data?: Partial<IORGDataTableQueryState>) => {
    return client.get(
      `/${endpoint}`,
      {
        ...data,
        action: 'findUser',
      },
      AuthorizedListEmployeePayloadSchema
    );
  },

  detailsGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, AuthorizedUserDetailsSchema);
  },

  createPOST: async (data: IAuthorizedListForm) => {
    return client.post(`/${endpoint}`, data, AuthorizedListSchema);
  },

  updatePUT: async (id?: number, data?: IAuthorizedListForm) => {
    return client.put(`/${endpoint}/${id}`, data, AuthorizedListSchema);
  },

  dataDELETE: async (id?: string) => {
    return client.delete(
      `/${endpoint}/${id}`,
      AuthorizedListDeletePayloadSchema
    );
  },

  listExpiringTrainingGET: async (authType: string, date: Date) => {
    const params = {
      filters: [
        { name: 'authType', value: authType },
        {
          name: 'date',
          value: Moment(date).utc(true).endOf('day').toDate(),
        },
      ],
    };
    return client.get(
      `/${endpoint}?action=findExpired`,
      params,
      AuthorizedExpiredListPayload
    );
  },

  substationEntryListGET: async () => {
    return client.get(
      `${endpoint}`,
      {
        action: 'findValidSubEntry',
        filters: [
          {
            name: 'date',
            value: new Date(),
          },
        ],
      },
      AuthorizedSubstationPayloadSchema
    );
  },

  suspensionHistoryListGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    return client.get(
      `/${suspensionEndpoint}`,
      params,
      AuthorizedSuspensionHistoryPayloadSchema
    );
  },

  suspensionHistoryListPOST: async (data) => {
    return client.post(
      `/${suspensionEndpoint}`,
      data,
      AuthorizedSuspensionHistoryAddPayloadSchema
    );
  },

  listCrewGET: async (date: Date) => {
    return client.get(
      `/${endpoint}?action=findCrew`,
      {
        date,
      },
      AuthorizedExpiredListPayload
    );
  },
};

export default services;
