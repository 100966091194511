import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMDatePicker,
  ATMCheckbox,
  ATMSelect,
} from 'shared-it-appmod-ui';
import { orderBy } from 'lodash';
import {
  IOutageRequestListPayloadItem,
  OutageRequestFilterSchema,
} from 'src/models/outages.model';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRequestStatusContext } from 'src/contexts/request-status.context';
import Moment from 'src/libraries/moment.library';
import { FacilityType } from 'src/constants';
import { useSubstationContext } from 'src/contexts/substation.context';
import Lang from 'src/libraries/language';
import { useShortDescriptionContext } from 'src/contexts/short-description.context';
import { shortDescriptionActionTypes } from 'src/ducks/short-description.duck';
import {
  getShortDescriptionList,
  getShortDescriptionStatus,
} from 'src/selectors/short-description.selector';
import { requestStatusActionTypes } from 'src/ducks/request-status.duck';
import { getRequestStatusStatus } from 'src/selectors/request-status.selector';
import { getOutageTypeStatus } from 'src/selectors/outage-type.selector';
import { outageTypeActionTypes } from 'src/ducks/outage-type.duck';
import {
  formatToRawEmployeeId,
  getEmployeeName,
} from 'src/helpers/employee.helper';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import style from './outages-list-search.module.scss';

type IProps = {
  data?: IOutageRequestListPayloadItem;
  handleSubmit: (data?: any) => void;
};

const OutagesListSearch: React.FC<IProps> = ({ handleSubmit }) => {
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { state: authListState, actions: authListActions } =
    useAuthorizedListContext();
  const { state: outageTypeState, actions: outageTypeActions } =
    useOutageTypeContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: requestStatusState, actions: requestStatusActions } =
    useRequestStatusContext();
  const { state: shortDescriptionState, actions: shortDescriptionActions } =
    useShortDescriptionContext();
  const loading = getShortDescriptionStatus(
    shortDescriptionState,
    shortDescriptionActionTypes.SHORT_DESCRIPTION_LIST_READ
  );
  const statusLoading = getRequestStatusStatus(
    requestStatusState,
    requestStatusActionTypes.REQUEST_STATUS_LIST_READ
  );

  const crewNameStatus = getAuthorizedListStatus(
    authListState,
    authorizedListActionTypes.AUTHORIZED_LIST_CREW_READ
  );

  const outageTypeLoading = getOutageTypeStatus(
    outageTypeState,
    outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ
  );

  const [facilityTypeId, setFacilityTypeId] = useState<number>();
  const [category, setCategory] = useState<number>();

  useEffect(() => {
    realtimeLogActions.lineGET();
    requestStatusActions.listGET({ page: 1, limit: 0 });
    outageTypeActions.activeListGET();
    substationActions.listGET({
      limit: 0,
      page: 1,
    });
    shortDescriptionActions.listGET();
    authListActions.listCrewGET(
      Moment()
        .set({
          date: 31,
          month: 12,
          year: new Date().getFullYear() - 2,
        })
        .toDate()
    );
  }, [
    realtimeLogActions,
    outageTypeActions,
    substationActions,
    requestStatusActions,
    shortDescriptionActions,
    authListActions,
  ]);

  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className={style.filterContainer}>
      <ATMForm
        onSubmit={handleSubmit}
        mode="onChange"
        defaultValues={{
          requestorName: '',
          crewName: '',
          facilityName: '',
          substationId: '',
          cancelNoteTxt: '',
          hasCancelNote: false,
        }}
        validationSchema={OutageRequestFilterSchema}
      >
        {({ control, formState, clearErrors, reset, resetField }) => {
          return (
            <ATMGrid columns={5}>
              <ATMGrid.Row>
                <ATMGrid.Column className={style.selectables}>
                  <ATMField
                    as={ATMInput}
                    name="facilityName"
                    label={Lang.LBL_FACILITY_NAME}
                    clearable
                    placeholder="Enter"
                    control={control}
                    onChange={([_, { value }]) => value}
                    // error={errors.logEntry}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column className={style.selectables}>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    name="outgTypId"
                    placeholder="Select"
                    label={Lang.LBL_AUTHORIZATION_TYPE}
                    control={control}
                    style={{ display: 'block', marginRight: '1em' }}
                    options={orderBy(
                      outageTypeState.list.map((value) => ({
                        key: value.outageTypeId,
                        text: value.outageTypeDesc,
                        value: value.outageTypeId,
                      })),
                      'text',
                      'asc'
                    )}
                    onChange={([_, { value }]) => value}
                    clearable
                    search
                    loading={outageTypeLoading.fetching}
                    disabled={outageTypeLoading.fetching}
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column className={style.selectables}>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    name="requestStat"
                    placeholder="Select"
                    label={Lang.LBL_OUTAGE_STATUS}
                    control={control}
                    style={{ display: 'block', marginRight: '1em' }}
                    options={requestStatusState.list
                      .filter((v) => !['In', 'Out'].includes(v.name))
                      .map((value) => ({
                        key: value.requestStatusId,
                        value: value.requestStatusId,
                        text: value.name,
                      }))}
                    onChange={([_, { value }]) => value}
                    clearable
                    search
                    loading={statusLoading.fetching}
                    disabled={statusLoading.fetching}
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column className={style.selectables}>
                  <ATMField
                    as={ATMDatePicker}
                    label={Lang.LBL_DATE_RANGE}
                    size="small"
                    type="range"
                    format="MM/DD/YYYY"
                    placeholder="Select"
                    control={control}
                    name="dateRange"
                    // error={errors.logDtTmFrcdOutOcurred}
                    onChange={([_, { value }]) => {
                      if (value && value.length === 2) {
                        const [start, end] = value;
                        return [
                          Moment(start).startOf('day').toDate(),
                          Moment(end).endOf('day').toDate(),
                        ];
                      }
                      return value;
                    }}
                    clearable
                  />
                </ATMGrid.Column>
                <ATMGrid.Column
                  verticalAlign="bottom"
                  className={style.selectables}
                >
                  <ATMButton
                    // label={' '}
                    type="button"
                    secondary
                    content={!showFilters ? 'More Filters' : 'Less Filters'}
                    onClick={() => setShowFilters(!showFilters)}
                    icon={showFilters ? 'angle up' : 'angle down'}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              {!showFilters ? (
                <span />
              ) : (
                <>
                  <ATMGrid.Row>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMSelect}
                        name="facilityType"
                        value={facilityTypeId}
                        label={Lang.LBL_FACILITY_TYPE}
                        placeholder={Lang.LBL_SELECT}
                        options={Object.values(FacilityType)
                          .filter((val) => typeof val === 'number')
                          .map((value, key) => ({
                            key,
                            value,
                            text: Lang.FACILITY_TYPE[value],
                          }))}
                        onChange={
                          ((_, { value }) => {
                            setFacilityTypeId(value);

                            return value;
                          }) as any
                        }
                        clearable
                        search
                        selectOnBlur={false}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column
                      className={style.selectables}
                      verticalAlign="bottom"
                    >
                      {facilityTypeId === FacilityType.Line ? (
                        <ATMField
                          name="outgFacId"
                          label={Lang.LBL_LINE}
                          placeholder={Lang.LBL_SELECT}
                          as={ATMSelect}
                          control={control}
                          options={realtimeLogState.line.map((value) => ({
                            key: value.outgFacId,
                            value: value.outgFacId,
                            text: value.outgFacNm,
                          }))}
                          onChange={([_, { value }]) => value}
                          clearable
                          search
                          selectOnBlur={false}
                        />
                      ) : (
                        <ATMField
                          name="substationId"
                          label={Lang.LBL_SUBSTATION}
                          placeholder={Lang.LBL_SELECT}
                          as={ATMSelect}
                          control={control}
                          options={substationState.list.map((value) => ({
                            key: value.substationId,
                            value: value.substationId,
                            text: value.name,
                          }))}
                          onChange={([_, { value }]) => value}
                          clearable
                          search
                          selectOnBlur={false}
                        />
                      )}
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMInput}
                        name="requestorName"
                        placeholder="Enter"
                        clearable
                        label="Requestor Name"
                        onChange={([_, { value }]) => value}
                        control={control}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMSelect}
                        selection
                        name="crewName"
                        placeholder="Select"
                        label={Lang.LBL_CREW_INFO_NAME}
                        control={control}
                        style={{ display: 'block', marginRight: '1em' }}
                        clearable
                        search
                        options={orderBy(
                          authListState?.crewList?.map((value, key) => ({
                            key,
                            value: formatToRawEmployeeId(
                              value.empId
                            ).toString(),
                            text: getEmployeeName({
                              ...(value.employee || {}),
                              empId: value.empId,
                            }),
                          })),
                          'text',
                          'asc'
                        )}
                        onChange={([_, { value }]) => value}
                        selectOnBlur={false}
                        loading={crewNameStatus.fetching}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMInput}
                        name="sortOrderNumber"
                        placeholder="Enter"
                        label={Lang.LBL_OUTAGES_WORK_ORDER_NUMBER}
                        control={control}
                        clearable
                        onChange={([_, { value }]) => value}
                      />
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                  <ATMGrid.Row>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMInput}
                        name="cancelNoteTxt"
                        placeholder="Enter"
                        clearable
                        label={Lang.LBL_CANCEL_NOTES}
                        control={control}
                        onChange={([_, { value }]) => value}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMCheckbox}
                        name="hasCancelNote"
                        label={Lang.LBL_OUTAGES_CANCEL_NOTES}
                        control={control}
                        selectOnBlur={false}
                        style={{ display: 'block', marginRight: '1em' }}
                        onChange={([_, { checked }]) => checked}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMInput}
                        name="woNbr"
                        clearable
                        placeholder="Enter"
                        label={Lang.LBL_WORK_ORDER_NUMBER}
                        control={control}
                        onChange={([_, { value }]) => value}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMSelect}
                        name="lerShortDescCatId"
                        control={control}
                        label="Short Description Category"
                        placeholder={Lang.LBL_SELECT}
                        clearable
                        search
                        options={shortDescriptionState.categories.map(
                          (value) => ({
                            key: value.catId,
                            value: value.catId,
                            text: value.shortDescCat,
                          })
                        )}
                        onChange={([_, { value }]) => {
                          setCategory(value);
                          resetField('lerShortDescId', undefined);
                          return value;
                        }}
                        loading={loading.fetching}
                        selection
                        selectOnBlur={false}
                        disabled={loading.fetching}
                      />
                    </ATMGrid.Column>
                    <ATMGrid.Column className={style.selectables}>
                      <ATMField
                        as={ATMSelect}
                        selection
                        name="lerShortDescId"
                        control={control}
                        label="Short Description"
                        placeholder={Lang.LBL_SELECT}
                        clearable
                        search
                        options={getShortDescriptionList(
                          category || 0,
                          shortDescriptionState.list
                        ).map((value) => ({
                          key: value.descId,
                          value: value.descId,
                          text: value.shortDesc,
                        }))}
                        onChange={([_, { value }]) => {
                          return value;
                        }}
                        disabled={!category || loading.fetching}
                        selectOnBlur={false}
                        loading={loading.fetching}
                      />
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                </>
              )}

              <ATMGrid.Row style={{ paddingTop: '0' }}>
                <ATMGrid.Column width={5}>
                  <div>
                    <ATMButton
                      primary
                      content="Search"
                      disabled={!formState.isDirty}
                    />
                    <ATMButton
                      secondary
                      type="button"
                      content="Clear Search"
                      onClick={() => {
                        handleSubmit(undefined);
                        if (reset) {
                          setFacilityTypeId('' as any);
                          resetField('lerShortDescId', {
                            defaultValue: 0,
                          });
                          resetField('lerShortDescCatId', {
                            defaultValue: 0,
                          });
                          reset();
                          setImmediate(() => {
                            clearErrors();
                          });
                        }
                      }}
                      disabled={!formState.isDirty}
                    />
                  </div>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default OutagesListSearch;
