import React, { useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
  ATMButton,
  ATMIcon,
  ATMPopover,
} from 'shared-it-appmod-ui';
import { IFacility, IFacilityFilter } from 'src/models/facility.model';
import Status from 'src/components/atoms/status/status.component';
import Lang from 'src/libraries/language';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import FacilityCard from 'src/components/pages/facility/facility-card/facility-card.component';
import styles from './facility-list.module.scss';
import FacilityAdd from '../facility-add/facility-add.component';
import FacilityDetails from '../facility-details/facility-details.component';
import FacilityEdit from '../facility-edit/facility-edit.component';
import FacilitySubstationGroupModal from '../facility-substation-group/facility-substation-group.component';

type IProps = {
  data: IFacility[];
  total: number;
  loading: boolean;
  handleFetch?: (params: IORGDataTableQueryState) => void;
  facilityActions: {
    isNew: boolean;
    isDetails: boolean;
    isEdit: boolean;
  };
  setFacilityActions: React.Dispatch<
    React.SetStateAction<{
      isNew: boolean;
      isDetails: boolean;
      isEdit: boolean;
    }>
  >;
  filters: IFacilityFilter;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const FacilityListView: React.FC<IProps> = ({
  data,
  total,
  loading,
  handleFetch,
  facilityActions,
  setFacilityActions,
  filters,
  handleDownload,
}) => {
  const [selectedFacility, setSelectedFacility] = useState(0);
  const columns: IORGDataTableColumn<IFacility>[] = [
    {
      index: 'outgFacNm',
      title: Lang.LBL_FACILITY_NAME,
      render: (_, facility) => {
        return (
          <FacilityCard
            facility={facility}
            setSelectedFacility={setSelectedFacility}
            setFacilityActions={setFacilityActions}
          />
        );
      },
    },
    {
      index: 'volt.voltNm',
      title: Lang.LBL_VOLTAGE,
    },
    {
      index: 'stat',
      title: Lang.LBL_STATUS,
      render: (value) => <Status value={value} label />,
    },
    {
      index: 'substation',
      title: Lang.LBL_SUBSTATION,
      render: (_, facility) => {
        if (!facility.substations?.length) {
          return String.fromCharCode(8211);
        }

        if (facility.substations.length > 1) {
          return (
            <span>
              {facility.substations && facility.substations[0].name},{' '}
              <span>
                <FacilitySubstationGroupModal data={facility} />{' '}
              </span>
            </span>
          );
        }
        return facility.substations && facility.substations[0].name;
      },
    },
    {
      index: 'besInd',
      title: Lang.LBL_BES,
      render: (value) => (value === 'Y' ? <b>{Lang.LBL_YES}</b> : Lang.LBL_NO),
    },
    {
      index: 'facTyp.facTypNm',
      title: Lang.LBL_FACILITY_TYPE,
    },
    {
      index: 'action',
      sortable: false,
      title: Lang.LBL_ACTION,
      width: '80px',
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, facility) => (
        <>
          <ATMButton
            onClick={() => {
              setSelectedFacility(facility.outgFacId);
              setFacilityActions({
                isNew: false,
                isDetails: false,
                isEdit: true,
              });
            }}
            icon="edit"
          />
        </>
      ),
    },
  ];

  switch (true) {
    case facilityActions.isNew === true:
      return <FacilityAdd setFacilityActions={setFacilityActions} />;
    case facilityActions.isDetails === true:
      return (
        <FacilityDetails
          selectedFacility={selectedFacility}
          setFacilityActions={setFacilityActions}
        />
      );
    case facilityActions.isEdit === true:
      return (
        <FacilityEdit
          selectedFacility={selectedFacility}
          setFacilityActions={setFacilityActions}
        />
      );
    default:
      return (
        <>
          <div className="admin-content">
            <ORGDataTable
              columns={columns}
              data={data}
              onChange={handleFetch}
              loading={loading}
              counter
              celled={false}
              total={total}
              sortable
              rowsPerPageOptions={[10, 25, 50, 100]}
              noDataText={
                <div className={styles.noData}>
                  <p>
                    <ATMIcon
                      circular
                      color="grey"
                      inverted
                      name="info"
                      size="small"
                      style={{
                        display: 'inline-block',
                        marginRight: '0.8em',
                        verticalAlign: 'bottom',
                      }}
                    />
                    {Lang.MSG_EMPTY_FILTER}
                  </p>
                </div>
              }
            >
              {({ state, setState }) => ({
                toolbars: {
                  substationId: ({ value, setValue }) => (
                    <span className={styles.noWrap}>
                      <ATMDropdown
                        placeholder={Lang.LBL_SUBSTATION}
                        selection
                        size="small"
                        clearable
                        value={value}
                        options={[
                          ...Array.from(new Set(filters.substations)).map(
                            (val) => {
                              return {
                                key: val.substationId,
                                value: val.substationId,
                                text: val.name,
                              };
                            }
                          ),
                        ]}
                        onChange={(_, val) => {
                          setValue(val.value);
                          return val.value;
                        }}
                        search
                        selectOnBlur={false}
                        loading={loading}
                      />
                    </span>
                  ),
                  facTypId: ({ value, setValue }) => (
                    <span className={styles.noWrap}>
                      <ATMDropdown
                        placeholder={Lang.LBL_FACILITY_TYPE}
                        selection
                        size="small"
                        clearable
                        value={value}
                        options={[
                          ...Array.from(new Set(filters.facTyps)).map((val) => {
                            return {
                              key: val.facTypId,
                              value: val.facTypId,
                              text: val.name,
                            };
                          }),
                        ]}
                        onChange={(_, val) => {
                          setValue(val.value);
                          return val.value;
                        }}
                        search
                        selectOnBlur={false}
                        loading={loading}
                      />
                    </span>
                  ),
                  voltId: ({ value, setValue }) => (
                    <span className={styles.selectables}>
                      <ATMDropdown
                        placeholder={Lang.LBL_VOLTAGE}
                        selection
                        size="small"
                        clearable
                        value={value}
                        options={[
                          ...Array.from(new Set(filters.volts)).map((val) => {
                            return {
                              key: val.voltId,
                              value: val.voltId,
                              text: val.name,
                            };
                          }),
                        ]}
                        onChange={(_, val) => {
                          setValue(val.value);
                          return val.value;
                        }}
                        search
                        selectOnBlur={false}
                        loading={loading}
                      />
                    </span>
                  ),
                  stat: ({ value, setValue }) => (
                    <span className={styles.selectables}>
                      <ATMDropdown
                        placeholder={Lang.LBL_STATUS}
                        selection
                        size="small"
                        clearable
                        value={value}
                        options={[
                          ...Array.from(new Set(filters.stats)).map((val) => {
                            return {
                              key: val,
                              value: val,
                              text: Lang.STATUS[val],
                            };
                          }),
                        ]}
                        onChange={(_, val) => {
                          setValue(val.value);
                          return val.value;
                        }}
                        selectOnBlur={false}
                        loading={loading}
                      />
                    </span>
                  ),
                  search: () => (
                    <TableSearch
                      field="outgFacNm"
                      state={state}
                      setState={setState}
                      placeholder={Lang.LBL_NAME_SEARCH}
                      compact
                    />
                  ),
                  buttons: () => (
                    <div>
                      <ATMPopover
                        position="bottom left"
                        content="Download"
                        on="hover"
                        trigger={
                          <ATMButton
                            key="download"
                            secondary
                            icon="download"
                            size="tiny"
                            onClick={() => {
                              handleDownload({
                                ...state,
                                page: 1,
                                limit: total,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  ),
                },
              })}
            </ORGDataTable>
          </div>
        </>
      );
  }
};

export default FacilityListView;
