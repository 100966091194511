import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMHeader,
  ATMLoader,
  ATMMessage,
  ATMSegment,
  ATMTab,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import FormError from 'src/components/atoms/form-error/form-error.component';

import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import LerRequestHeader from 'src/components/organisms/ler-request-header/ler-request-header.component';
import {
  AccessRole,
  FacilityType,
  LERRequestFormStep,
  LERRequestPage,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import {
  hasChangeRequest,
  hasEditAccess,
  isCaisoDetailsNotUpdated,
} from 'src/helpers/ler-request.helper';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasAccess, hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import {
  ILerRequestForm,
  LerRequestFormSchema,
  ILerRequest,
  ILerRequestOutageFacilityForm,
} from 'src/models/ler-request.model';
import { IAuthStatementForm } from 'src/models/auth-statement.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { getUser } from 'src/libraries/amplify.library';
import LERRequestAuthorizationForm from '../ler-request-authorization/ler-request-authorization-form/ler-request-authorization-form.component';
import LERRequestCrewInformationForm from '../ler-request-crew-information/ler-request-crew-information-form/ler-request-crew-information-form.component';
import LERRequestISO from '../ler-request-iso/ler-request-iso-form/ler-request-iso-form.component';
import LERRequestJobInformationForm from '../ler-request-job-information/ler-request-job-information-form/ler-request-job-information-form.component';
import LERRequestOutageDateCalendar from '../ler-request-outage-date/ler-request-outage-date-calendar/ler-request-outage-date-calendar.component';
import LERRequestOutageFacilityForm from '../ler-request-outage-facility/ler-request-outage-facility-form/ler-request-outage-facility-form.component';
import LERRequestCostForm from '../ler-request-cost/ler-request-cost-form/ler-request-cost-form.component';
import LERRequestSystemForm from '../ler-request-system/ler-request-system-form/ler-request-system-form.component';
import styles from './ler-request-edit.module.scss';
import LERRequestSwitchingForm, {
  isSortStatusComplete,
} from '../ler-request-switching/ler-request-switching-form/ler-request-switching-form.component';
import LerRequestReject from '../ler-request-reject/ler-request-reject.component';
import LerRequestApprove from '../ler-request-approve/ler-request-approve.component';
import LerRequestActionList from '../ler-request-action-list/ler-request-action-list.component';
import LerRequestUpdate from '../ler-request-update/ler-request-update.component';

const scrollToTop = () => {
  const element = document.getElementById('form-error');
  if (element) {
    element.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }
};
interface IProps {
  trigger?: React.ReactNode;
  activeTab?: LERRequestFormStep;
  isEdit?: boolean;
  viewType?: string;
  // setPrintLoader?: (params: boolean) => void;
}

const Content: React.FC<
  IProps & {
    id: number;
    setClose: () => void;
  }
> = ({ id, activeTab, setClose }) => {
  const { state, actions } = useLerRequestContext();
  const {
    handleChange,
    params: { active },
  } = useLocationParams([
    LERRequestPage.View,
    LERRequestPage.Edit,
    LERRequestPage.Clone,
    'active',
  ]);
  const formRef = useRef<HTMLFormElement>(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFileCached, setIsFileCached] = useState(false);
  const [isUpdateDone, setIsUpdateDone] = useState(false);
  const [printLoader, setPrintLoader] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<LERRequestFormStep>(
    active || activeTab || LERRequestFormStep.OUTAGE_FACILITY
  );

  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { state: facilityState, actions: facilityActions } = useFacilityContext();

  const { submitUpload } = useSharepoint();
  const { data } = state;
  const updateStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_UPDATE
  );
  const historyStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_UPDATE
  );
  const loadingFacilityGroups = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_USER_GRP_DETAILS_READ
  );
  const removeUploadHistory = useCallback(() => {
    fileAction.deleteAllStashedFiles();
  }, [fileState, fileAction]);

  const isChangeRequest = hasChangeRequest(data);

  const isValidForRejection =
    [LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
      data?.requestStat as LERRequestStatus
    ) && !isChangeRequest;

  const handleFileUploadSubmit = useCallback(
    async (responseData: ILerRequest) => {
      // const uploader = `${responseData?.updatedByEmployee?.firstName} ${responseData?.updatedByEmployee?.lastName}`;
      // responseData?.updatedByEmployee?.fullName ?? '-';
      // const uploader = requestor; // `${getUser()?.first_name} ${getUser()?.last_name}`;
      let uploader = '';
      if (responseData?.updatedByEmployee) {
        uploader = `${responseData.updatedByEmployee?.firstName} ${responseData.updatedByEmployee?.lastName}`;
      } else {
        uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      }

      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.requestId) || '',
        parentFolder: responseData?.requestId as any,
        requestId: responseData?.requestId,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.LER_TYPE,
        setting: settingState,
        withParentFolder: true,
        setIsUpdated,
      });
    },
    [fileState, settingState, setIsUpdated]
  );

  useEffect(() => {
    const fetchData = async () => {
      actions.setCaisoList();
      const result = await actions.dataGET(id);
      if (
        (result.payload?.requestStat === LERRequestStatus.Approved ||
          result.payload?.requestStat === LERRequestStatus.Scheduled ||
          result.payload?.requestStat === LERRequestStatus.Completed) &&
        (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ||
          hasRole(AccessRole.MCC_REAL_TIME_OPERATOR))
      ) {
        await actions.dataCaisoGET(id);
      }

      if (result.error) {
        setClose();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (result.payload) {
          actions.setPreviousData(result.payload);
        }
      }
    };

    fetchData();
    return () => {
      actions.sendSystemChangeTaskEmail(false);
    };
  }, []);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ILerRequestForm) => {
      if (data) {
        await actions.setCaisoError(null);
        if (
          !facilityState.lerUserGrp ||
          facilityState.outgFacId !== formData?.outageFacility?.outgFacId
        ) {
          await facilityActions.userGrpDetails(
            formData?.outageFacility?.outgFacId ?? 0
          );
        }

        const filteredList = formData?.sortResponses?.filter(
          (val) => !isSortStatusComplete(val?.sortStat as string)
        );
        const outageFacilityData = {
          facilityType: formData?.outageFacility?.facilityType,
          facTypId: formData?.outageFacility?.facTypId,
          outgFacId: formData?.outageFacility?.outgFacId,
          outgTypId: formData?.outageFacility?.outgTypId,
          substationId:
            formData?.outageFacility?.facilityType === FacilityType.Station
              ? formData?.outageFacility?.substationId
              : null,
          voltId: formData?.outageFacility?.voltId,
        } as ILerRequestOutageFacilityForm;

        const authorizationStatementData = {
          authStmnt: formData?.authorizationStatement?.authStmnt,
          cancelNote: formData?.authorizationStatement?.cancelNote,
          dailyNotes: formData?.authorizationStatement?.dailyNotes,
          emailGroups:
            !facilityState.lerUserGrp || facilityState.lerUserGrp.length === 0
              ? null
              : formData.authorizationStatement?.emailGroups,
        } as IAuthStatementForm;

        const validData: ILerRequestForm = {
          ...formData,
          authorizationStatement: authorizationStatementData,
          outageFacility: outageFacilityData,
          sortResponses: (filteredList || []).filter(
            (v) => v.sent || v.cancellation
          ),
        };

        const noCaisoUpdates = isCaisoDetailsNotUpdated(validData, data);
        actions.setPreviousData(state?.data as unknown as any);
        const result = await actions.updatePUT(data?.requestId, validData);
        if (result.error) {
          removeUploadHistory();
        }
        if (result.payload) {
          await handleFileUploadSubmit(result.payload);
          removeUploadHistory();
          if (
            result.payload.requestStat === LERRequestStatus.Approved ||
            result.payload.requestStat === LERRequestStatus.Scheduled
          ) {
            actions.sendSystemChangeTaskEmail(true);
          }
          ToastSuccess(
            hasChangeRequest(result.payload)
              ? Lang.MSG_LER_REQUEST_CHANGE_SUCCESS
              : Lang.MSG_LER_REQUEST_UPDATE_SUCCESS
          );
          if (result.payload?.outageEmail) {
            if (result.payload.outageEmail.successOutageEmail?.length) {
              result.payload.outageEmail.successOutageEmail.map(
                (emailOutage) => {
                  ToastSuccess(
                    emailOutage?.message ?? 'Email sent successfully'
                  );
                }
              );
            }
            if (result.payload?.outageEmail?.failedOutageEmail?.length) {
              result.payload.outageEmail.failedOutageEmail.map(
                (emailOutage) => {
                  ToastError(emailOutage?.message ?? 'Failed to send email');
                }
              );
            }
          }

          if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) === false) {
            handleChange({
              [LERRequestPage.View]: data.requestId,
              [LERRequestPage.Edit]: undefined,
              [LERRequestPage.Clone]: undefined,
            });
          } else {
            // eslint-disable-next-line no-lonely-if
            if (
              ((result.payload.requestStat === LERRequestStatus.Approved ||
                result.payload.requestStat === LERRequestStatus.Scheduled) &&
                noCaisoUpdates === false) ||
              ((result.payload.requestStat === LERRequestStatus.Approved ||
                result.payload.requestStat === LERRequestStatus.Scheduled) &&
                facilityState.lerUserGrp &&
                facilityState.lerUserGrp.length !== 0)
            ) {
              actions.setForApproval(true);
              actions.setIsFromUpdate(true);
              actions.setIsFromReject(false);
              actions.setHasNoCaisoUpdate(noCaisoUpdates);
            }
          }
        }
        setIsUpdateDone(true);
      }
    },
    [data, state, actions, fileState, fileAction]
  );

  if (!data || (data && data.requestId !== id)) {
    return <ATMLoader active />;
  }

  const isVersionMaxedForCR =
    data &&
    data.version_no &&
    data.version_no >= 99 &&
    (([
      LERRequestStatus.Submitted,
      LERRequestStatus.Approved,
      LERRequestStatus.Scheduled,
    ].includes(data.requestStat as LERRequestStatus) &&
      hasRole([AccessRole.MCC_OUTAGE_REQUESTOR])) ||
      ([LERRequestStatus.Approved, LERRequestStatus.Scheduled].includes(
        data.requestStat as LERRequestStatus
      ) &&
        hasRole([AccessRole.MCC_SUBMITTING_SUPERVISOR])));

  if (data && hasChangeRequest(data)) {
    return (
      <ATMMessage color="orange">
        <ATMGrid>
          <ATMGrid.Row columns={2}>
            <ATMGrid.Column>
              <h5>{Lang.TTL_CHANGE_REQUEST_PENDING}</h5>
              <p>{Lang.MSG_LER_CHANGE_REQUEST}</p>
            </ATMGrid.Column>
            <ATMGrid.Column textAlign="right" verticalAlign="middle">
              <ATMButton
                primary
                type="button"
                content={Lang.LBL_LER_VIEW_CHNG_REQ}
                onClick={() =>
                  handleChange({
                    [LERRequestPage.Edit]: undefined,
                    [LERRequestPage.View]: id,
                    [LERRequestPage.Clone]: undefined,
                  })
                }
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMMessage>
    );
  }

  if (isVersionMaxedForCR) {
    return (
      <ATMMessage color="orange">
        <ATMGrid>
          <ATMGrid.Row columns={2}>
            <ATMGrid.Column>
              <h5>{Lang.TTL_MAX_VERSION_REACHED}</h5>
              <p>{Lang.MSG_LER_MAX_REVISION_REACHED}</p>
            </ATMGrid.Column>
            <ATMGrid.Column textAlign="right" verticalAlign="middle">
              <ATMButton
                primary
                type="button"
                content={Lang.LBL_LER_BACK_TO_VIEW}
                onClick={() =>
                  handleChange({
                    [LERRequestPage.Edit]: undefined,
                    [LERRequestPage.Clone]: undefined,
                    [LERRequestPage.View]: id,
                  })
                }
              />
              <ATMButton
                primary
                type="button"
                content={Lang.LBL_LER_CLONE}
                onClick={() =>
                  handleChange({
                    [LERRequestPage.Edit]: undefined,
                    [LERRequestPage.View]: undefined,
                    [LERRequestPage.Clone]: id,
                  })
                }
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMMessage>
    );
  }

  if (hasEditAccess(data) === false) {
    handleChange(
      {
        [LERRequestPage.Edit]: undefined,
        [LERRequestPage.Clone]: undefined,
        [LERRequestPage.View]: id,
      },
      undefined,
      true
    );

    return null;
  }

  const panes = [
    {
      key: LERRequestFormStep.OUTAGE_FACILITY,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.OUTAGE_FACILITY],
    },
    {
      key: LERRequestFormStep.OUTAGE_DATE,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.OUTAGE_DATE],
    },
    {
      key: LERRequestFormStep.JOB_INFORMATION,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.JOB_INFORMATION],
    },
    {
      key: LERRequestFormStep.CREW_INFORMATION,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.CREW_INFORMATION],
    },
    {
      key: LERRequestFormStep.AUTHORIZATION_STATEMENT,
      access: AccessType.OUTAGE_REQUEST_AUTH_STATEMENT,
      menuItem:
        Lang.LER_REQUEST_STEP[LERRequestFormStep.AUTHORIZATION_STATEMENT],
    },
    {
      key: LERRequestFormStep.ISO,
      access: AccessType.OUTAGE_REQUEST_ISO,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.ISO],
    },
    {
      key: LERRequestFormStep.SWITCHING,
      access: AccessType.OUTAGE_REQUEST_SWITCHING,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.SWITCHING],
    },
    {
      key: LERRequestFormStep.SYSTEM_CHANGE,
      access: AccessType.OUTAGE_REQUEST_SYSTEM,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.SYSTEM_CHANGE],
    },
    {
      key: LERRequestFormStep.COST,
      access: AccessType.OUTAGE_REQUEST_COST,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.COST],
    },
  ].filter((v) => !v.access || hasAccess(v.access));

  return (
    <ATMForm
      key={`ler_edit_${data.version_no}`}
      ref={formRef}
      defaultValues={data as unknown as ILerRequestForm}
      validationSchema={LerRequestFormSchema}
      mode="onChange"
      onError={scrollToTop}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <div className={styles.header}>
              <ATMSegment attached>
                <ATMGrid columns={2}>
                  <ATMGrid.Column verticalAlign="middle">
                    <LerRequestHeader data={data} />
                  </ATMGrid.Column>
                  <ATMGrid.Column textAlign="right">
                    <ul className={styles.right}>
                      <li>
                        <div className="action-buttons">
                          <LerRequestReject
                            data={data}
                            handleSuccess={() => {
                              if (
                                hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ===
                                false
                              ) {
                                setClose();
                              }
                            }}
                            isRejectAfterSchedule={isValidForRejection}
                          />
                          <LerRequestApprove
                            data={data}
                            handleSuccess={async () => {
                              if (
                                hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ===
                                false
                              ) {
                                setClose();
                              } else {
                                handleChange({
                                  [LERRequestPage.View]: data.requestId,
                                  [LERRequestPage.Edit]: undefined,
                                  [LERRequestPage.Clone]: undefined,
                                });
                                if (
                                  !facilityState.lerUserGrp ||
                                  facilityState.outgFacId !==
                                    data.outageFacility?.outgFacId
                                ) {
                                  await facilityActions.userGrpDetails(
                                    data.outageFacility?.outgFacId ?? 0
                                  );
                                }
                                setImmediate(() => {
                                  actions.setForApproval(true);
                                  actions.setIsFromUpdate(false);
                                  actions.setIsFromReject(false);
                                  actions.setIsFromPendingCaiso(false);
                                });
                              }
                            }}
                          />

                          {/* <ATMButton
                            primary
                            type="button"
                            loading={
                              historyStatus.fetching ||
                              updateStatus.fetching ||
                              loadingFacilityGroups.fetching
                            }
                            disabled={
                              historyStatus.fetching ||
                              updateStatus.fetching ||
                              !(props.formState.isDirty || isFileCached) ||
                              loadingFacilityGroups.fetching
                            }
                            onClick={handleClick}
                          >
                            {Lang.LBL_UPDATE}
                          </ATMButton> */}

                          <LerRequestUpdate
                            loading={historyStatus.fetching ||
                              updateStatus.fetching ||
                              loadingFacilityGroups.fetching}
                            disabled={historyStatus.fetching ||
                              updateStatus.fetching ||
                              !(props.formState.isDirty || isFileCached) ||
                              loadingFacilityGroups.fetching}
                            handleUpdate={handleClick}
                          />
                          <LerRequestActionList
                            data={data}
                            setClose={setClose}
                            setPrintLoader={setPrintLoader}
                            printLoader={printLoader}
                          />
                        </div>
                      </li>

                      <li>
                        <div className="action-buttons">
                          <ATMButton
                            type="button"
                            color="grey"
                            basic
                            icon="external alternate"
                            onClick={() => {
                              window
                                .open(window.location.href, '_blank')
                                ?.focus();
                            }}
                          />

                          {setClose && (
                            <ATMButton
                              type="button"
                              color="grey"
                              basic
                              icon="close"
                              onClick={setClose}
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                  </ATMGrid.Column>
                </ATMGrid>
              </ATMSegment>
              <ATMTab
                panes={panes}
                activeIndex={panes.findIndex((v) => v.key === tabIndex)}
                onTabChange={(_, value) => {
                  scrollToTop();
                  if (value.activeIndex !== undefined && value.panes) {
                    const key = value.panes[value.activeIndex]
                      .key as LERRequestFormStep;
                    setTabIndex(key);
                  }
                }}
                menu={{ secondary: true, pointing: true }}
                renderActiveOnly={false}
              />
              {/* {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) &&
              data?.requestStat === LERRequestStatus.Scheduled ? (
                <LERSystemChangeTasksEmailPrompt />
              ) : (
                ''
              )} */}
            </div>

            <div className="admin-container">
              <div id="form-error">
                <FormError
                  errors={props.formState.errors}
                  handleItemClick={(error) => {
                    setTabIndex(error.name.split('.')[0] as LERRequestFormStep);
                  }}
                  dictionary="FORM_LER_REQUEST"
                />
              </div>
              <div
                className={
                  tabIndex !== LERRequestFormStep.OUTAGE_FACILITY
                    ? 'hidden'
                    : undefined
                }
              >
                <LERRequestOutageFacilityForm isEdit />
              </div>
              {tabIndex === LERRequestFormStep.OUTAGE_DATE && (
                <LERRequestOutageDateCalendar isEdit />
              )}
              <div
                className={
                  tabIndex !== LERRequestFormStep.JOB_INFORMATION
                    ? 'hidden'
                    : undefined
                }
              >
                <LERRequestJobInformationForm
                  isEdit
                  id={id}
                  isSubmitted={isUpdated}
                  viewType={isUpdated ? 'update' : 'edit'}
                  setIsFileCached={setIsFileCached}
                  isUpdateDone={isUpdateDone}
                  setIsUpdateDone={setIsUpdateDone}
                />
              </div>
              <div
                className={
                  tabIndex !== LERRequestFormStep.CREW_INFORMATION
                    ? 'hidden'
                    : undefined
                }
              >
                <LERRequestCrewInformationForm />
              </div>
              <AccessComponent
                type={AccessType.OUTAGE_REQUEST_AUTH_STATEMENT}
                when={tabIndex === LERRequestFormStep.AUTHORIZATION_STATEMENT}
              >
                <LERRequestAuthorizationForm id={id} isEdit />
              </AccessComponent>
              <AccessComponent type={AccessType.OUTAGE_REQUEST_ISO}>
                <div
                  className={
                    tabIndex !== LERRequestFormStep.ISO ? 'hidden' : undefined
                  }
                >
                  {state.caisoError && (
                    <ATMMessage negative>
                      <ATMHeader as="h3">{` CAISO Response : ${state.caisoError.message}`}</ATMHeader>
                      <p>{state.caisoError.description}</p>
                    </ATMMessage>
                  )}
                  <LERRequestISO data={data} />
                </div>
              </AccessComponent>
              <AccessComponent
                type={AccessType.OUTAGE_REQUEST_SWITCHING}
                when={tabIndex === LERRequestFormStep.SWITCHING}
              >
                <LERRequestSwitchingForm />
              </AccessComponent>

              <AccessComponent
                type={AccessType.OUTAGE_REQUEST_SYSTEM}
                when={tabIndex === LERRequestFormStep.SYSTEM_CHANGE}
              >
                <LERRequestSystemForm />
              </AccessComponent>
              <AccessComponent type={AccessType.OUTAGE_REQUEST_COST}>
                <div
                  className={
                    tabIndex !== LERRequestFormStep.COST ? 'hidden' : undefined
                  }
                >
                  <LERRequestCostForm />
                </div>
              </AccessComponent>
            </div>
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

const LERRequestEdit: React.FC<IProps> = ({ trigger }) => {
  const { params: currentTab, handleChange } = useLocationParams([
    LERRequestPage.Edit,
    'active',
  ]);
  const { actions: lerActions } = useLerRequestContext();
  const id = Number(currentTab.lerEdit);

  return (
    <MOLRightPanel
      isOpen={!!id}
      onClose={() => {
        handleChange();
        lerActions.setCaisoError(null);
        lerActions.setCaisoList();
      }}
      onOpen={
        trigger
          ? () =>
              handleChange({
                ...currentTab,
                [LERRequestPage.Edit]: id,
              })
          : undefined
      }
      trigger={trigger}
      className={styles.floatingPanel}
      width="calc(100vw - 200px)"
      closeOnDimmerClick={false}
    >
      <Content
        id={id}
        setClose={() => {
          handleChange();
          lerActions.setCaisoError(null);
          lerActions.setCaisoList();
        }}
      />
    </MOLRightPanel>
  );
};

export default React.memo(LERRequestEdit);
