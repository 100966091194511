import { SharePointAccessTokenSession } from 'src/constants/sharepoint-common.constant';
import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import { IFile } from 'src/models/file.model';
import services from 'src/services/file.service';

export const fileActionTypes = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  FILE_DELETE: 'FILE_DELETE',
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',
  FILE_LIST_FETCH: 'FILE_LIST_FETCH',
  FILE_DATA_CREATE: 'FILE_DATA_CREATE',
  FILE_DATA_DELETE: 'FILE_DATA_DELETE',
  FILE_STASH: 'FILE_STASH',
  FILE_DELETE_ALL_STASH: 'FILE_DELETE_ALL_STASH',
  FILE_REMOVE: 'FILE REMOVE',
  FILE_SHAREPOINT_UPLOAD: 'FILE_SHAREPOINT_UPLOAD',
  FILE_SHAREPOINT_LIST: 'FILE_SHAREPOINT_LIST',
  FILE_TOKEN_FETCH: 'FILE_TOKEN_FETCH',
  FILE_SHAREPOINT_DELETE: 'FILE_SHAREPOINT_DELETE',
  FILE_SHAREPOINT_FETCH_CACHED_TOKEN: 'FILE_SHAREPOINT_FETCH_CACHED_TOKEN',
} as const;

export const duckActions = {
  uploadPOST: {
    type: fileActionTypes.FILE_UPLOAD,
    service: services.uploadPOST,
    meta: { error: false },
  },
  uploadDELETE: {
    type: fileActionTypes.FILE_DELETE,
    service: services.uploadDELETE,
  },
  downloadGET: {
    type: fileActionTypes.FILE_DOWNLOAD,
    service: services.downloadGET,
  },
  listByRequestIdGET: {
    type: fileActionTypes.FILE_LIST_FETCH,
    service: services.listByRequestIdGET,
  },
  addPOST: {
    type: fileActionTypes.FILE_DATA_CREATE,
    service: services.addPOST,
    meta: { error: false },
  },
  dataDELETE: {
    type: fileActionTypes.FILE_DATA_DELETE,
    service: services.dataDELETE,
  },
  tokenGET: {
    type: fileActionTypes.FILE_TOKEN_FETCH,
    service: services.tokenGET,
  },
  listSharepoint: {
    type: fileActionTypes.FILE_SHAREPOINT_LIST,
    service: services.listSharepoint,
  },
  uploadSharePoint: {
    type: fileActionTypes.FILE_SHAREPOINT_UPLOAD,
    service: services.uploadSharePoint,
  },
  deleteDocumentSharePoint: {
    type: fileActionTypes.FILE_SHAREPOINT_DELETE,
    service: services.deleteDocumentSharepoint,
  },
  stashFile: (data: File | File[]) => ({
    type: fileActionTypes.FILE_STASH,
    payload: Array.isArray(data) ? data : [data],
  }),
  deleteAllStashedFiles: () => ({
    type: fileActionTypes.FILE_DELETE_ALL_STASH,
    payload: [],
  }),
  append: (data: IFile | IFile[]) => ({
    type: fileActionTypes.FILE_UPLOAD,
    payload: data,
  }),
  remove: (data: IFile) => ({
    type: fileActionTypes.FILE_DELETE,
    payload: data,
  }),
  removeFileFromStash: (data: any) => ({
    type: fileActionTypes.FILE_REMOVE,
    payload: data,
  }),
  fetchCachedToken: (param: Date) => ({
    type: fileActionTypes.FILE_SHAREPOINT_FETCH_CACHED_TOKEN,
    payload: param,
  }),
};

export type IFileSharePointType = {
  cachedToken: any;
  timeLastFetched: Date;
};

export type IFileActions = typeof duckActions;
export interface IFileState extends ICommonState<typeof fileActionTypes> {
  data?: IFile;
  list: IFile[];
  stash: File[];
  filename_list?: any[];
  cachedAccessToken: IFileSharePointType;
}

export const defaultState: IFileState = {
  status: {},
  list: [],
  stash: [],
  filename_list: [],
  cachedAccessToken: {
    cachedToken: undefined as any,
    timeLastFetched: undefined as any,
  },
};

const FileReducer = (
  state: IFileState = defaultState,
  action: IReducerAction<IFileActions>
): IFileState => {
  switch (action.type) {
    case fileActionTypes.FILE_UPLOAD: {
      if (action.payload) {
        if (Array.isArray(action.payload)) {
          return {
            ...state,
            list: [...state.list, ...action.payload],
          };
        }

        return {
          ...state,
          data: action.payload,
          list: [...state.list, action.payload],
        };
      }

      return state;
    }

    case fileActionTypes.FILE_DELETE: {
      return {
        ...state,
        list: state.list.filter(
          (value) => value.fileName !== action.payload?.fileName
        ),
      };
    }

    case fileActionTypes.FILE_REMOVE: {
      const fileStashed = state.stash.filter(
        (value) => value.name !== action.payload
      );
      const fileNames: any[] = [];
      fileStashed?.map((val) => {
        fileNames.push(val.name);
      });
      return {
        ...state,
        filename_list: fileNames,
        stash: fileStashed,
      };
    }

    case fileActionTypes.FILE_LIST_FETCH: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
      };
    }

    case fileActionTypes.FILE_DATA_CREATE: {
      if (action.payload) {
        return {
          ...state,
          list: [...state.list, action.payload],
        };
      }

      return state;
    }

    case fileActionTypes.FILE_DATA_DELETE: {
      if (action.params) {
        const [uploadId] = action.params;

        return {
          ...state,
          list: state.list.filter((file) => file.uploadId !== uploadId),
        };
      }

      return state;
    }

    case fileActionTypes.FILE_STASH: {
      const fileNames: any[] = [];
      action.payload?.map((val) => {
        fileNames.push(val.name);
      });
      return {
        ...state,
        filename_list: fileNames.sort(),
        stash: action.payload ?? [],
      };
    }

    case fileActionTypes.FILE_DELETE_ALL_STASH: {
      return {
        ...state,
        stash: [],
        filename_list: [],
      };
    }

    case fileActionTypes.FILE_TOKEN_FETCH: {
      const data: IFileSharePointType = {
        cachedToken: action.payload?.accessToken ?? undefined,
        timeLastFetched: new Date(),
      };
      sessionStorage.setItem(
        SharePointAccessTokenSession.SHAREPOINT_ACCESS_TOKEN,
        JSON.stringify(data)
      );
      return {
        ...state,
        cachedAccessToken: data,
      };
    }

    case fileActionTypes.FILE_SHAREPOINT_FETCH_CACHED_TOKEN: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default FileReducer;
