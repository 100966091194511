import { AccessRole, SettingName } from 'src/constants';
import { useSettingContext } from 'src/contexts/setting.context';
import { hasRole } from 'src/libraries/access.library';
import Moment from 'src/libraries/moment.library';

export const useSetting = () => {
  const { state } = useSettingContext();

  return [...state.list].reduce(
    (list, item) => ({
      ...list,
      [item.name]: item.value,
    }),
    {} as Record<SettingName, any>
  );
};

export const useSettingConfig = (facilityBesInd: string) => {
  const { BES_SUBMITTAL_WINDOW_DAYS, ENFORCE_BES_SUBMITTAL_WINDOW } =
    useSetting();

  let minOutageDate =
    hasRole(AccessRole.MCC_OUTAGE_REQUESTOR) &&
    ENFORCE_BES_SUBMITTAL_WINDOW === 'YES'
      ? Moment().add(BES_SUBMITTAL_WINDOW_DAYS, 'day')
      : Moment().add(9, 'day');

  if (
    hasRole([
      AccessRole.MCC_OUTAGE_REQUESTOR,
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
    ]) &&
    ENFORCE_BES_SUBMITTAL_WINDOW === 'YES' &&
    facilityBesInd === 'Y'
  ) {
    minOutageDate = Moment().add(BES_SUBMITTAL_WINDOW_DAYS, 'day');
  } else if (
    hasRole([
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
      AccessRole.MCC_REAL_TIME_OPERATOR,
      AccessRole.MCC_OUTAGE_REQUESTOR,
      AccessRole.MCC_OUTAGE_VIEWER,
    ])
  ) {
    minOutageDate = Moment().add(9, 'day');
  }

  if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
    minOutageDate = Moment();
  }

  return {
    minOutageDate: minOutageDate.startOf('day'),
  };
};
