import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMSegment,
  ATMHeader,
  ATMButton,
  ATMTable,
} from 'shared-it-appmod-ui';
import FileDelete from 'src/components/atoms/file-delete/FileDelete';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import {
  SharePointSubmitRequestType,
  useSharepoint,
} from 'src/hooks/file.hook';
import Lang from 'src/libraries/language';
import { IFileSharePoint } from 'src/models/file.model';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import {
  ITroubleTicketPayload,
  ITroubleTicketResponsePayload,
} from 'src/models/trouble-ticket.model';
import { getUser } from 'src/libraries/amplify.library';
import style from '../trouble-ticket.module.scss';

type IProp = {
  responsePayload: Partial<ITroubleTicketPayload>;
  viewType: ViewType;
  enableRemoveButton?: boolean;
  isSubmitted?: boolean;
  handleDocuments?: () => void;
  setIsFileCached?: (param: boolean) => void;
  onDelete?: (data) => void;
  columns?: any;
  id?: number;
  isViewMode: boolean;
  view: any;
  status: string;
};

export enum ViewType {
  EDIT = 'Edit',
  VIEW = 'View',
  CREATE = 'Create',
}

const TroubleTicketDocumentDetail: React.FC<IProp> = ({
  isViewMode,
  viewType,
  id,
  enableRemoveButton,
  view,
  status = 'new',
  responsePayload,
  onDelete,
}) => {
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { fetchMccList, submitUpload } = useSharepoint();
  const [mccListFile, setMccListFile] = useState<IFileSharePoint[]>([]);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    fileAction?.deleteAllStashedFiles();
  }, []);

  useEffect(() => {
    setFiles(fileState.filename_list ?? []);
  }, [fileState.stash]);

  const getMccList = useCallback(async () => {
    let request: SharePointSubmitRequestType = {} as any;
    const requestData: ISharepointRequestData = {
      accessToken: '',
      createdBy: '',
      documentId: '',
      fileId: '',
      fileName: '',
      folderName: JSON.stringify(id) || '',
      parentFolder: id as any,
      requestId: id as any,
      requestor: '',
      documentLibrary: '',
    };
    request = {
      applicationType: SharePointApplicationType.TROUBLE_TICKET_TYPE,
      requestData,
      setMccListFile,
      withParentFolder: true,
      setting: settingState,
      files: fileState.stash,
    };
    await fetchMccList(request);
  }, [settingState, fetchMccList, setMccListFile]);

  useEffect(() => {
    if (view !== 'createTicket') {
      getMccList();
    }
  }, [id]);

  const handleFileUploadSubmit = useCallback(
    async (responseData: ITroubleTicketResponsePayload, fileList: File[]) => {
      // const requestor = responseData?.updatedByEmployee.fullName || '';
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.troubleTicketId) || '',
        parentFolder: responseData?.troubleTicketId as any,
        requestId: responseData?.troubleTicketId,
        requestor: uploader,
        documentLibrary: '',
      };
      if (fileList.length) {
        await submitUpload({
          requestData,
          files: fileList,
          applicationType: SharePointApplicationType.TROUBLE_TICKET_TYPE,
          setting: settingState,
          withParentFolder: true,
        });
        await getMccList();
      }
    },
    [settingState]
  );

  const docsTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_UPLOADED_DATE}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_UPLOADED_BY}</ATMTable.HeaderCell>
          {!isViewMode && status.toLowerCase() !== 'closed' ? (
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          ) : (
            ''
          )}
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {mccListFile.length ? (
          mccListFile?.map((item, i) => (
            <ATMTable.Row key={i}>
              <ATMTable.Cell>
                <>
                  <a
                    key={item.fileUrl as any}
                    href={item.fileUrl as any}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {item.fileName}
                  </a>
                </>
              </ATMTable.Cell>
              <ATMTable.Cell>{item.uploadDate ?? '-'}</ATMTable.Cell>
              <ATMTable.Cell>{item.uploadedBy ?? '-'}</ATMTable.Cell>
              {!isViewMode && status.toLowerCase() !== 'closed' ? (
                <ATMTable.Cell>
                  <FileDelete
                    successMessage="File successfully deleted"
                    errorMessage="Failed to delete file"
                    file={item}
                    displayConfirmation
                    handleSuccess={() => getMccList()}
                  />
                </ATMTable.Cell>
              ) : (
                ''
              )}
            </ATMTable.Row>
          ))
        ) : (
          <>
            <ATMTable.Row
              textAlign={
                !isViewMode && status.toLowerCase() !== 'closed'
                  ? 'right'
                  : 'center'
              }
            >
              <ATMTable.Cell />
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
              {!isViewMode && status.toLowerCase() !== 'closed' ? (
                <ATMTable.Cell />
              ) : (
                ''
              )}
            </ATMTable.Row>
          </>
        )}
      </ATMTable.Body>
    </ATMTable>
  );

  const reviewTable = (
    <>
      {files?.length && !enableRemoveButton ? (
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell>
                {Lang.LBL_REQUESTOR_NAME}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {files?.length
              ? files?.map((item, i) => (
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMButton
                        type="button"
                        icon="close"
                        onClick={() => fileAction.removeFileFromStash(item)}
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              : ''}
          </ATMTable.Body>
        </ATMTable>
      ) : (
        ''
      )}
      {fileState.filename_list?.length && enableRemoveButton
        ? fileState.filename_list?.map((item, i) => (
            <span key={i}>
              <ATMButton
                type="button"
                icon="close"
                onClick={() => {
                  fileAction.removeFileFromStash(item);
                  if (onDelete) {
                    onDelete(fileState.filename_list);
                  }
                }}
              />
              {item}
            </span>
          ))
        : ''}
    </>
  );
  const tableContent = (
    <>
      <ATMSegment className="applySegment">
        <ATMHeader className={style.headerSegment}>
          <h4>{Lang.LBL_TROUBLE_TICKET_DOCUMENTS}</h4>
          <div style={isViewMode ? { display: 'none' } : { fontSize: '14px' }}>
            {status.toLowerCase() !== 'closed' ? (
              <FileUploader
                text={Lang.LBL_ADD_DOCUMENTS}
                icon="plus"
                onSuccess={(fileList) => {
                  if (viewType === ViewType.EDIT) {
                    handleFileUploadSubmit(responsePayload as any, fileList);
                  }
                }}
                displayRemoveButton={false}
                successMessage="'Your file(s) has been cached, but will not be uploaded until the Trouble Ticket is Created/Submitted"
                errorMessage="Failed to cache file(s)"
                displayMessage={viewType === ViewType.CREATE}
              />
            ) : (
              ''
            )}
          </div>
        </ATMHeader>
        <div>
          {reviewTable}
          {docsTable}
        </div>
      </ATMSegment>
    </>
  );
  return <div>{tableContent}</div>;
};

export default TroubleTicketDocumentDetail;
