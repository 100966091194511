/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDropdown,
  ATMButton,
  ATMTextArea,
  ATMMessage,
} from 'shared-it-appmod-ui';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { getUser } from 'src/libraries/amplify.library';
import Lang from 'src/libraries/language';
import {
  IKeyRequestDemandIssueValidationForm,
  KeyRequestDemandIssueValidationFormSchema,
} from 'src/models/key-request.model';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import style from '../key-request.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IKeyRequestDemandIssueValidationForm) => void;
  handleEnable: (value: boolean) => void;
  isDropdownOpen?: boolean;
  handleDropdown?: (data) => void;
};

const KeyRequestDemandForm: React.FC<IProp> = ({
  formRef,
  handleSubmit,
  handleEnable,
  isDropdownOpen,
  handleDropdown,
}) => {
  const [key, setKey] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    valid: false,
    message: '',
  });

  const handleFile = () => {};
  const userId = getUser()?.emp_no;

  const { state, actions } = useKeyRequestContext();

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_VALIDATE_READ
  );

  useEffect(() => {
    if (key.length === 0) {
      setShowMessage(false);
    }
  }, [key]);

  const handleKeyStatus = useCallback(
    async (control, getValues) => {
      const result = await actions?.validateGET(
        getValues('keyRequestDetail.keyNbr'),
        getValues('keyRequestDetail.noOfKeys')
      );

      if (!result.error) {
        handleEnable(true);
      }

      setShowMessage(true);
      setValidationMessage({
        valid: !result.error,
        message: result.payload
          ? result.payload?.message
          : result.error.message,
      });
    },
    [actions, showMessage, validationMessage]
  );
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      validationSchema={KeyRequestDemandIssueValidationFormSchema}
    >
      {({ control, formState: { errors }, getValues }) => {
        // handleEnable(formState.isDirty);
        return (
          <>
            {/* hidden form */}
            <ATMField
              name="requestTyp"
              control={control}
              defaultValue="New Key"
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestorTyp"
              control={control}
              defaultValue="D"
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestedBy"
              control={control}
              defaultValue={userId}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.approvalEmail"
              control={control}
              defaultValue={false}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.noOfKeys"
              control={control}
              defaultValue={1}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />

            <ATMGrid columns={2}>
              <ATMGrid.Row columns="equal">
                <ATMGrid.Column>
                  <ATMField
                    as={ATMDropdown}
                    control={control}
                    name="keyRequestDetail.keyTypId"
                    label={<LabelRequired>{Lang.LBL_KEY_TYPE}</LabelRequired>}
                    error={errors.keyRequestDetail?.keyTypId}
                    placeholder="Select"
                    selection
                    clearable
                    required
                    selectOnBlur={false}
                    options={[
                      ...Array.from(Object.values(Lang.KEY_TYPE)).map((val) => {
                        return {
                          key: val,
                          value: val,
                          text: Lang.KEY_TYPE[val],
                        };
                      }),
                    ]}
                    onChange={([_, { value }]) => {
                      setKey(value);
                      return value;
                    }}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMField
                    as={DropdownEmployee}
                    control={control}
                    error={errors.empId}
                    required
                    name="empId"
                    label={
                      <LabelRequired>
                        {Lang.LBL_KEY_REQUEST_KEY_HOLDER_EMP_ID}
                      </LabelRequired>
                    }
                    onChange={([_, { value }]) => value}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns="equal">
                <ATMGrid.Column>
                  {/* US #597770 Increase character limit to 500 */}
                  <ATMField
                    as={ATMTextArea}
                    name="keyRequestDetail.keyDetail.reason"
                    size="large"
                    maxLength={500}
                    label={
                      <LabelRequired>
                        {Lang.LBL_KEY_REQUEST_INSSUANCE_REASON}
                      </LabelRequired>
                    }
                    required
                    control={control}
                    error={errors.keyRequestDetail?.keyDetail?.reason}
                    placeholder="Enter"
                    onChange={([_, { value }]) => {
                      // This is a workaround for textarea field that is inside a modal. The issue is when typing a space
                      // it is not working properly and is because of the component (dropdown) behind the modal. This fix ensures
                      // that when typing in the textarea, the dropdown is not open.
                      if (isDropdownOpen && handleDropdown) {
                        handleDropdown(false);
                      }
                      return value;
                    }}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns="equal">
                <ATMGrid.Column>
                  <label className={style.uploadBtn}>
                    {Lang.LBL_ATTACH_APPROVER_EMAIL}
                  </label>
                  <ATMField
                    name="fileBtn"
                    as={ATMInput}
                    control={control}
                    type="hidden"
                    className="hidden"
                  />
                  <FileUploader
                    icon="upload"
                    onSuccess={handleFile}
                    text={Lang.LBL_UPLOAD}
                    successMessage="Your file(s) has been cached, but will not be uploaded until the Key Request is Created/Submitted"
                    errorMessage="Failed to cache file(s)"
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns="equal">
                <ATMGrid.Column columns="2">
                  <ATMField
                    as={ATMInput}
                    name="keyRequestDetail.keyNbr"
                    label={
                      <LabelRequired>
                        {Lang.LBL_KEY_REQUEST_ASSIGN_KEY_NUMBER}
                      </LabelRequired>
                    }
                    type="number"
                    control={control}
                    onBlur={(_, value) => (value ? [value] : undefined)}
                    error={errors.keyRequestDetail?.keyNbr}
                    disabled={!getValues().keyRequestDetail?.keyTypId}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMButton
                    type="button"
                    secondary
                    name="validateKey"
                    content={Lang.LBL_VALIDATE_AVAILABILITY}
                    className={style.validateBtn}
                    onClick={() => handleKeyStatus(control, getValues)}
                    loading={status.fetching}
                    disabled={
                      !!errors.keyRequestDetail?.keyNbr ||
                      !getValues().keyRequestDetail?.keyNbr
                    }
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Column columns="2">
                {showMessage ? (
                  validationMessage.valid ? (
                    <ATMMessage positive>
                      <h4>{Lang.MSG_VALID_KEY_NUMBERS}</h4>
                      <p>{validationMessage.message}</p>
                      {validationMessage.valid}
                    </ATMMessage>
                  ) : (
                    <ATMMessage negative>
                      <h4>{Lang.MSG_INVALID_KEY_NUMBERS}</h4>
                      <p>{validationMessage.message}</p>
                      {validationMessage.valid}
                    </ATMMessage>
                  )
                ) : null}
              </ATMGrid.Column>
            </ATMGrid>
          </>
        );
      }}
    </ATMForm>
  );
};

export default KeyRequestDemandForm;
