import React, { useState } from 'react';
import { ATMButton, ATMField, ATMModal, ATMTextArea, useATMFormContext } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';
import styles from './ler-request-update.module.scss';

type IProps = {
    loading: boolean;
    disabled: boolean;
    handleUpdate: () => void;
  };

const LerRequestUpdate: React.FC<IProps> = ({ handleUpdate, loading, disabled }) => {
    const {
        control,
        formState: { errors },
      } = useATMFormContext<ILerRequestForm>();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <ATMModal
              open={isOpen}
              size="tiny"
              onClose={() => setIsOpen(false)}
              onOpen={() => setIsOpen(true)}
              closeOnDimmerClick={false}
              trigger={<ATMButton
                primary
                content={Lang.LBL_UPDATE}
                type="button"
                loading={loading}
                disabled={disabled}
              />}
            >
          <ATMModal.Header>Update LER Request</ATMModal.Header>
          <ATMModal.Content>
            <p>{Lang.MSG_UPDATE_LER}</p>
          <ATMField
            className={styles.updateReason}
            name="updateReason"
            label={Lang.LBL_REASON_OF_UPDATE}
            control={control}
            as={ATMTextArea}
            error={errors.updateReason}
            charLimit={1000}
            charCount
          />
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              secondary
              content={Lang.LBL_CANCEL}
              onClick={() => setIsOpen(false)}
              type="button"
            />
            <ATMButton
              type="button"
              primary
              content={Lang.LBL_CONFIRM}
              onClick={() => {
                setIsOpen(false);
                handleUpdate();
              }}
            />
          </ATMModal.Actions>
        </ATMModal>
        </>
    );
};

export default LerRequestUpdate;
