import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';

export const FileShape = {
  id: yup.number(), // For forms
  uploadId: yup.number().nullable(),
  requestId: yup.number(),
  fileName: yup.string().required().typeError(Lang.MSG_ERROR_REQUIRED),
  docName: yup.string().nullable(),
  app: yup.string(),
  container: yup.string(),
  environment: yup.string(),
  message: yup.string(),
  uploadTypeId: yup.number().nullable(),
  uploadedBy: yup.string().nullable(),
  uploadDate: yup.string().nullable(),
  downloadType: yup.string().nullable(),
  fileUrl: yup.string().nullable(),
};

export const FileSharePointShape = {
  ...FileShape,
  fileId: yup.string(),
  docName: yup.string(),
  uploadedBy: yup.string(),
  documentLibraryName: yup.string(),
  parentFolderName: yup.string(),
  folderName: yup.string(),
  listName: yup.string(),
  fileName: yup.string(),
  createdBy: yup.string(),
  targetFile: yup.string(),
  isEditView: yup.boolean(),
};

export const FileSchema = yup.object(FileShape);

export const FileDownloadPayloadSchema = yup
  .object({
    fileUrl: yup.string().required(),
  })
  .defined();

export const FileUploadListPayload = yup
  .object({
    count: yup.number(),
    rows: yup.array().of(FileSchema.clone()),
  })
  .defined();

export const FileFormSchema = yup.object({
  fileName: yup.string().required(),
  file: yup.mixed().required(),
});

export const FileListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(FileSchema.clone()),
});
export const FileSharePointSchema = yup.object(FileSharePointShape);
export type IFile = yup.Asserts<typeof FileSchema>;
export type IFileForm = yup.Asserts<typeof FileFormSchema>;
export type IFileSharePoint = yup.Asserts<typeof FileSharePointSchema>;
