import yup from 'src/libraries/validator.library';
import { EmployeeAddonSchema } from './employee.model';

export const AuthorizedListTrainingSchema = yup.object({
  authSubcategoryId: yup.number().nullable(),
  authListId: yup.string(),
  empId: yup.string(),
  authTypId: yup.string(),
  trainingDate: yup.date(),
  contracShortNm: yup.string().nullable(),
  trainerEmpId: yup.string().nullable(),
  testFailInd: yup.boolean(),
  trainerName: yup.string().nullable(),
  expiryDate: yup.string().nullable(),
  suspendFlag: yup.boolean(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  subcategory: yup
    .object({
      description: yup.string(),
    })
    .nullable(),
});

export const AuthorizedListShape = {
  id: yup.number(),
  fullName: yup.string().nullable(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  empId: yup.string(),
  ccCd: yup.string().nullable(),
  contracShortNm: yup.string().nullable(),
  employeeStat: yup.string(),
  tempActiveInd: yup.string().nullable(),
  trainings: yup.array().of(AuthorizedListTrainingSchema.clone()),
  contractorFirm: yup
    .object({
      fullName: yup.string().nullable(),
    })
    .nullable(),
  supervisorNumber: yup.string().nullable(),
  supervisor: yup
    .object({
      empId: yup.string().nullable(),
      lastName: yup.string().nullable(),
      firstName: yup.string().nullable(),
      middleName: yup.string().nullable(),
    })
    .nullable(),
};

export const AuthorizedExpiredListShape = {
  authListId: yup.number().required(),
  empId: yup.string().required(),
  employee: EmployeeAddonSchema.clone().nullable(),
  authTypId: yup.string().required(),
  trainingDate: yup.date().nullable(),
  contracShortNm: yup.string().nullable(),
  authSubcategoryId: yup.string().nullable(),
  trainerEmpId: yup.string().nullable(),
  testFailInd: yup.boolean().required(),
  expiryDate: yup.date().required(),
  suspendFlag: yup.boolean().nullable(),
  trainerName: yup.string().nullable(),
};

export const AuthorizedExpiredListSchema = yup.object(
  AuthorizedExpiredListShape
);

export const AuthorizedExpiredListPayload = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(AuthorizedExpiredListSchema.clone()),
});

export const AuthorizedHistoryDetailsShape = {
  authTyp: yup.string().required(),
  authDesc: yup.string().required(),
  eventTime: yup.date(),
  event: yup.string().required(),
  comment: yup.string().required(),
  updatedBy: yup.string(),
};

export const AuthorizedUserDetailsShape = {
  id: yup.number(),
  employeeName: yup.string().required(),
  authTraining: yup.string().required(),
  lineSwitching: yup.string().required(),
  substationSwitching: yup.string().required(),
  substationEntry: yup.string().required(),
};

export const AuthorizedHistoryDetailsSchema = yup.object(
  AuthorizedHistoryDetailsShape
);

export const AuthorizedUserDetailsSchema = yup.object({
  ...AuthorizedUserDetailsShape,
  history: yup.array(AuthorizedHistoryDetailsSchema),
});

export const AuthorizedListSchema = yup.object(AuthorizedListShape);

export const AuthorizedListFormSchema = yup.object({
  authListId: yup.number(),
  empId: yup.string().required(),
  fullName: yup.string().nullable(),
  contracShortNm: yup.string().nullable(),
  cntrctFlg: yup.boolean(),
  authTypId: yup.string().required(),
  employeeStat: yup.string(),
  contractorFirm: yup
    .object({
      fullName: yup.string().nullable(),
    })
    .nullable(),
  trainerEmpId: yup.string().required(),
  trainingDate: yup.date().required(),
  authSubcategoryId: yup.string().nullable(),
  testFailInd: yup.boolean(),
  createdBy: yup.string(),
  createdAt: yup.date(),
});

export const AuthorizedListSuspensionFormSchema = yup.object({
  empId: yup.string().required(),
  authTypId: yup.string().required(),
  authListId: yup.number().nullable(),
  eventTime: yup.date().nullable(),
  event: yup.string().nullable(),
  suspEventId: yup.number().nullable(),
  comments: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const AuthorizedListListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(AuthorizedListSchema.clone()),
});

export const AuthorizedSubstationSchema = yup.object({
  authSubcategoryId: yup.number().nullable(),
  authListId: yup.string(),
  empId: yup.string(),
  authTypId: yup.string(),
  trainingDate: yup.string(),
  contracShortNm: yup.string().nullable(),
  trainerEmpId: yup.string(),
  testFailInd: yup.boolean(),
  trainerName: yup.string().nullable(),
  expiryDate: yup.string(),
  suspendFlag: yup.boolean(),
  updatedAt: yup.string(),
  updatedBy: yup.string(),
  employee: yup.object({
    fullName: yup.string().nullable(),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    middleName: yup.string().nullable(),
    empId: yup.string(),
    contracShortNm: yup.string().nullable(),
  }),
});

export const AuthorizedSubstationPayloadSchema = yup.object({
  rows: yup.array().of(AuthorizedSubstationSchema.clone()),
  count: yup.number(),
});

export const AuthorizedSuspensionHistoryShape = {
  suspensionHistoryId: yup.number(),
  empId: yup.string().required(),
  authTypId: yup.string().required(),
  suspEventId: yup.number(),
  comments: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
};

export const AuthorizedSuspensionHistorySchema = yup.object(
  AuthorizedSuspensionHistoryShape
);

export const AuthorizedSuspensionHistoryPayloadSchema = yup.object({
  rows: yup.array().of(AuthorizedSuspensionHistorySchema.clone()),
  count: yup.number(),
});

export const AuthorizedSuspensionHistoryAddPayloadSchema = yup.object(
  AuthorizedSuspensionHistoryShape
);

export const AuthorizedListDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const AuthorizedListSearchSchema = yup.object({
  contracShortNm: yup.string(),
  alphabetically: yup.string(),
  empId: yup.string(),
  supervisorNumber: yup.string(),
  ccCd: yup.string(),
  authTypId: yup.string(),
  empType: yup.string(),
  name: yup.string(),
});

const AuthorizedItemSchema = yup.object({
  expire_dt: yup.string().nullable(),
  supspension_ind: yup.boolean(),
  test_fail_ind: yup.boolean(),
  sub_auth_typ_desc: yup.string().nullable(),
});

export const AuthorizedListEmployeeSchema = yup.object({
  contr_short_nm: yup.string().nullable(),
  nm_lt_frst: yup.string(),
  emp_id: yup.string(),
  auth_training: AuthorizedItemSchema.nullable(),
  sub_switching: AuthorizedItemSchema.nullable(),
  sub_entry: AuthorizedItemSchema.nullable(),
  field_switching: AuthorizedItemSchema.nullable(),
});

export const AuthorizedListEmployeePayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(AuthorizedListEmployeeSchema),
});

export type IAuthorizedList = yup.Asserts<typeof AuthorizedListSchema>;
export type IAuthorizedListTraining = yup.Asserts<
  typeof AuthorizedListTrainingSchema
>;
export type IAuthorizedListForm = yup.Asserts<typeof AuthorizedListFormSchema>;
export type IAuthorizedListSuspensionForm = yup.Asserts<
  typeof AuthorizedListSuspensionFormSchema
>;
export type IAuthorizedHistoryDetailsList = yup.Asserts<
  typeof AuthorizedHistoryDetailsSchema
>;
export type IAuthorizedHistoryAdd = yup.Asserts<
  typeof AuthorizedSuspensionHistoryAddPayloadSchema
>;
export type IAuthorizedUserDetailsList = yup.Asserts<
  typeof AuthorizedUserDetailsSchema
>;
export type IAuthorizedExpiredListSchema = yup.Asserts<
  typeof AuthorizedExpiredListSchema
>;

export type IAuthorizedListSearch = yup.Asserts<
  typeof AuthorizedListSearchSchema
>;

export type IAuthorizedSubstation = yup.Asserts<
  typeof AuthorizedSubstationSchema
>;

export type IAuthorizedSuspensionHistory = yup.Asserts<
  typeof AuthorizedSuspensionHistorySchema
>;

export type IAuthorizedItem = yup.Asserts<typeof AuthorizedItemSchema>;
export type IAuthorizedListEmployee = yup.Asserts<
  typeof AuthorizedListEmployeeSchema
>;
