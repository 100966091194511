/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React, { useCallback } from 'react';
import {
  ORGDataTable,
  ATMDropdown,
  ATMDatePicker,
  ATMButton,
  IORGDataTableQueryState,
  IORGDataTableColumn,
  MOLInfoText,
  ATMLoader,
  IORGDataTableUpdateOptions,
  ATMPopover,
} from 'shared-it-appmod-ui';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { TroubleTicketStatus, TroubleTicketTab } from 'src/constants';
import { useTroubleTicketContext } from 'src/contexts/trouble-ticket.context';
import { useWorkGroupContext } from 'src/contexts/work-group.context';
import { troubleTicketActionTypes } from 'src/ducks/trouble-ticket.duck';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import { ITroubleTicketList } from 'src/models/trouble-ticket.model';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { getTroubleTicketStatus } from 'src/selectors/trouble-ticket.selector';
import { orderBy } from 'lodash';
import history from 'src/history';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import TroubleTicketCard from '../trouble-ticket-card/trouble-ticket-card.component';
import styles from '../trouble-ticket.module.scss';
import TroubleTicketListSearchFilter from './trouble-ticket-list-search-filter.component';

type IProps = {
  data: ITroubleTicketList[];
  loading: boolean;
  handleFetch?: (
    params: IORGDataTableQueryState,
    options: IORGDataTableUpdateOptions
  ) => void;
  setDataEdit: React.Dispatch<ITroubleTicketList>;
  setView: React.Dispatch<any>;
  total: number;
  troubleTotal: number;
  scheduledTotal: number;
  currentTab: number;
  isEditOpen: boolean;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  counter: number;
  lineSubstation: string | undefined;
  setIsLineSubstation: React.Dispatch<React.SetStateAction<string | undefined>>;
  searchFilterHistory: any;
};

const TroubleTicketListTables: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  currentTab,
  counter,
  lineSubstation,
  setIsLineSubstation,
  searchFilterHistory,
}) => {
  const { state: troubleTicketState } = useTroubleTicketContext();
  const { state: workGroupState } = useWorkGroupContext();

  const status = getTroubleTicketStatus(
    troubleTicketState,
    troubleTicketActionTypes.TROUBLE_TICKET_LIST_ALL_READ
  );

  const searchStatus = getTroubleTicketStatus(
    troubleTicketState,
    troubleTicketActionTypes.TROUBLE_TICKET_DATA_READ
  );

  const statusDropdown = 'All Statuses';
  const priorityDropdown = 'All Priorities';
  const priorityDropdownKey = 'All_Priorities';

  const statusOption = Object.keys(Lang.TROUBLE_TICKET_STATUS)
    .filter((val) => val !== 'Closed')
    .map((val) => {
      return {
        text: Lang.TROUBLE_TICKET_STATUS[val],
        value: val,
        key: val,
      };
    });

  const priorityOption = Object.keys(Lang.TROUBLE_TICKET_PRIORITY).map(
    (val) => {
      return {
        text: Lang.TROUBLE_TICKET_PRIORITY[val],
        value: val,
        key: val,
      };
    }
  );

  const getAge = (date: string) => {
    const dateToday = moment();
    const beginDate = moment(date);
    return dateToday.diff(beginDate, 'days');
  };

  const handleEmployeeName = (fullname, firstname, lastname) => {
    let employeeName;
    const fullName = checkValue(fullname);
    const firstName = checkValue(firstname);
    const lastName = checkValue(lastname);
    const firstAndLastname = `${lastName}${firstName}`.replace(/\s*,/g, '');
    if (firstAndLastname.length) {
      if (
        firstAndLastname.search(/undefined/gi) === -1 &&
        firstAndLastname.search(/null/gi) === -1
      ) {
        employeeName = `${lastName}, ${firstName}`;
      } else {
        employeeName = Lang.LBL_UNAVAILABLE;
      }
    } else if (
      fullName.search(/undefined/gi) === -1 &&
      fullName.search(/null/gi) === -1
    ) {
      employeeName = fullName;
    } else {
      employeeName = Lang.LBL_UNAVAILABLE;
    }

    return employeeName.length ? employeeName : Lang.LBL_UNAVAILABLE;
  };

  const handleDownload = useCallback(() => {
    let list: ITroubleTicketList[] = [];
    switch (+currentTab) {
      case TroubleTicketTab.Trouble: {
        list = troubleTicketState.list;
        break;
      }
      case TroubleTicketTab.Scheduled: {
        list = troubleTicketState.listScheduled;
        break;
      }
    }

    if (list) {
      const isScheduled = +currentTab === TroubleTicketTab.Scheduled ?? false;
      const items = list.map((value) =>
        [
          checkValue(value.troubleTicketId),
          checkValue(TroubleTicketStatus[value.ticketStatus.toUpperCase()]),
          checkValue(value.beginDt ? formatDate(value.beginDt) : '--'),
          checkValue(value.dueDt ? formatDate(value.dueDt) : '--'),
          isScheduled
            ? checkValue(value.etrDt ? formatDate(value.etrDt) : '--')
            : '',
          checkValue(value.workGroup?.trbGrpNm),
          checkValue(
            value.substationId && value.substation
              ? value.substation.name
              : value.outgFacId && value.line
              ? value.line.outgFacNm
              : '--'
          ),
          checkValue(
            value.troubleEquipmentTypeId
              ? value.troubleEquipmentType?.name
              : '--'
          ),
          checkValue(value.ticketDesc),
          checkValue(value.ticketPrio),
          checkValue(
            handleEmployeeName(
              value.submittedByEmployee.fullName,
              value.submittedByEmployee.firstName,
              value.submittedByEmployee.lastName
            ) === Lang.LBL_UNAVAILABLE
              ? '--'
              : handleEmployeeName(
                  value.submittedByEmployee.fullName,
                  value.submittedByEmployee.firstName,
                  value.submittedByEmployee.lastName
                )
          ),
          checkValue(
            handleEmployeeName(
              value?.assignedToEmployee.fullName,
              value.assignedToEmployee.firstName,
              value.assignedToEmployee.lastName
            ) === Lang.LBL_UNAVAILABLE
              ? '--'
              : handleEmployeeName(
                  value?.assignedToEmployee.fullName,
                  value.assignedToEmployee.firstName,
                  value.assignedToEmployee.lastName
                )
          ),
          checkValue(getAge(value.beginDt.toString())),
        ].filter(Boolean)
      );

      const { exportData, format } = convertToCSV(
        buildExportData(
          items,
          [
            Lang.LBL_TROUBLE_TICKET_ID,
            Lang.LBL_TROUBLE_TICKET_STATUS,
            Lang.LBL_BEGIN_DATE,
            Lang.LBL_DUE_DATE,
            isScheduled ? Lang.LBL_ETR_DATE : '',
            Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
            Lang.LBL_TROUBLE_TICKET_FAC_SUBS,
            Lang.LBL_EQUIPMENT_TYPE,
            Lang.LBL_TROUBLE_TICKET_DESCRIPTION,
            Lang.LBL_PRIORITY,
            Lang.LBL_CREATED_BY,
            Lang.LBL_TROUBLE_TICKET_ASSIGNED_TO,
            Lang.LBL_TROUBLE_TICKET_AGE,
          ].filter(Boolean)
        )
      );
      const link = document.createElement('a');
      link.setAttribute('href', cleanSpecialCharacterData(exportData));
      if (isScheduled) {
        link.setAttribute(
          'download',
          `MCC_Trouble_Ticket_Scheduled_${Moment().format(
            'YYYYMMDD'
          )}.${format}`
        );
      } else {
        link.setAttribute(
          'download',
          `MCC_Trouble_Ticket_Trouble_${Moment().format('YYYYMMDD')}.${format}`
        );
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [troubleTicketState]);

  const columnsTrouble: IORGDataTableColumn<ITroubleTicketList>[] = [
    {
      index: 'troubleTicketId',
      title: 'Ticket ID/Desc',
      width: '30%',
      render: (_, value) => (
        <>
          <TroubleTicketCard troubleTicket={value} />
          <span className={styles.prioMargin}>
            <ListStatus value={value.ticketPrio} />
          </span>
          <MOLInfoText transparent limit={50} content={value.ticketDesc} />
        </>
      ),
    },
    {
      index: 'ticketStatus',
      title: Lang.LBL_TROUBLE_TICKET_STATUS,
      render: (_, value) => <ListStatus value={value.ticketStatus} label />,
    },
    {
      index: 'beginDt',
      title: Lang.LBL_BEGIN_DATE,
      render: (_, value) => (value.beginDt ? formatDate(value.beginDt) : '--'),
    },
    {
      index: 'dueDt',
      title: Lang.LBL_DUE_DATE,
      render: (_, value) => (
        <>
          {value.dueDt ? formatDate(value.dueDt) : '--'}
          {moment().diff(formatDate(value.dueDt), 'days') > 0 ? (
            // && value.ticketStatus === 'active'
            <p className={styles.overdueMsg}>
              {moment().diff(formatDate(value.dueDt), 'days')} day(s) overdue
            </p>
          ) : undefined}
        </>
      ),
    },
    {
      index: 'workGroup.trbGrpNm',
      title: Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
    },
    {
      index: 'facilitySubstation',
      title: Lang.LBL_TROUBLE_TICKET_FAC_SUBS,
      sortable: false,
      render: (_, value) => {
        if (value.substationId && value.substation) {
          return value.substation.name;
        }
        if (value.outgFacId && value.line) {
          return value.line.outgFacNm;
        }
        return '--';
      },
    },
    {
      index: 'troubleEquipmentType.name',
      title: Lang.LBL_TROUBLE_TICKET_EQUIP_TYPE,
      render: (_, value) =>
        value.troubleEquipmentTypeId ? value.troubleEquipmentType?.name : '--',
    },
    {
      index: 'age',
      title: Lang.LBL_TROUBLE_TICKET_AGE,
      sortable: false,
      render: (_, value) => {
        return getAge(value.beginDt.toString());
      },
    },
  ];

  const columnsScheduled: IORGDataTableColumn<ITroubleTicketList>[] = [
    {
      index: 'troubleTicketId',
      title: 'Ticket ID/Desc',
      width: '25%',
      render: (_, value) => (
        <>
          <TroubleTicketCard troubleTicket={value} />
          <span className={styles.prioMargin}>
            <ListStatus value={value.ticketPrio} />
          </span>
          <MOLInfoText transparent limit={50} content={value.ticketDesc} />
        </>
      ),
    },
    {
      index: 'ticketStatus',
      title: Lang.LBL_TROUBLE_TICKET_STATUS,
      render: (_, value) => <ListStatus value={value.ticketStatus} label />,
    },
    {
      index: 'beginDt',
      title: Lang.LBL_BEGIN_DATE,
      render: (_, value) => (value.beginDt ? formatDate(value.beginDt) : '--'),
    },
    {
      index: 'dueDt',
      title: Lang.LBL_DUE_DATE,
      render: (_, value) => (
        <>
          {value.dueDt ? formatDate(value.dueDt) : '--'}
          {moment().diff(formatDate(value.dueDt), 'days') > 0 ? (
            // && value.ticketStatus === 'active'
            <p className={styles.overdueMsg}>
              {moment().diff(formatDate(value.dueDt), 'days')} day(s) overdue
            </p>
          ) : undefined}
        </>
      ),
    },
    {
      index: 'etrDt',
      title: Lang.LBL_ETR_DATE,
      render: (_, value) => (value.etrDt ? formatDate(value.etrDt) : '--'),
    },
    {
      index: 'workGroup.trbGrpNm',
      title: Lang.LBL_TROUBLE_TICKET_WORK_GROUP,
    },
    {
      index: 'facilitySubstation',
      title: Lang.LBL_TROUBLE_TICKET_FAC_SUBS,
      sortable: false,
      render: (_, value) => {
        if (value.substationId && value.substation) {
          return value.substation.name;
        }
        if (value.outgFacId && value.line) {
          return value.line.outgFacNm;
        }
        return '--';
      },
    },
    {
      index: 'troubleEquipmentType.name',
      title: Lang.LBL_TROUBLE_TICKET_EQUIP_TYPE,
      render: (_, value) =>
        value.troubleEquipmentTypeId ? value.troubleEquipmentType?.name : '--',
    },
    {
      index: 'age',
      title: Lang.LBL_TROUBLE_TICKET_AGE,
      sortable: false,
      render: (_, value) => {
        return getAge(value.beginDt.toString());
      },
    },
  ];

  if (status.fetching) {
    return <ATMLoader active size="massive" inline="centered" />;
  }

  const { limit, ...defaultState } = searchFilterHistory || {};

  return (
    <div className="admin-content">
      <ORGDataTable
        defaultState={defaultState}
        location={history.location}
        handleLocation={(url) => {
          // Fixed issue with default state of table
          history.push(url.replace(/limit=\d+/g, 'limit=99999'));
        }}
        key={currentTab}
        celled={false}
        columns={
          +currentTab === TroubleTicketTab.Trouble
            ? columnsTrouble
            : columnsScheduled
        }
        sortable
        data={data}
        onChange={handleFetch}
        loading={loading || searchStatus.fetching}
        showPagination={false}
        counter
      >
        {() => ({
          toolbars: {
            ticketStatus: ({ value, setValue }) => (
              <ATMDropdown
                size="small"
                name="ticketStatus"
                selection
                clearable
                value={value}
                placeholder="Status"
                options={[
                  {
                    key: 'Both',
                    value: 'Both',
                    text: statusDropdown,
                  },
                  ...statusOption,
                ]}
                onChange={(_, val) => {
                  setValue(val.value);
                  return val.value;
                }}
                selectOnBlur={false}
              />
            ),
            ticketPrio: ({ value, setValue }) => (
              <ATMDropdown
                size="small"
                name="ticketPrio"
                selection
                clearable
                value={value}
                placeholder="Priority"
                options={[
                  {
                    key: priorityDropdownKey,
                    value: 'all',
                    text: priorityDropdown,
                  },
                  ...priorityOption,
                ]}
                onChange={(_, val) => {
                  setValue(val.value);
                  return val.value;
                }}
                selectOnBlur={false}
              />
            ),
            workGroups: ({ value, setValue }) => {
              return (
                <ATMDropdown
                  style={{ width: '100%' }}
                  size="small"
                  name="workGroups"
                  selection
                  clearable
                  multiple
                  search
                  placeholder="Work Group"
                  value={value ? [...value] : []}
                  options={orderBy(
                    workGroupState.list
                      .filter((elemt) => elemt.trbGrpNm.slice(0, 1) !== 'z')
                      .map((val) => ({
                        key: val.trbGrpId.toString(),
                        value: val.trbGrpId.toString(),
                        text: val.trbGrpNm,
                        sorttext: val.trbGrpNm.toLowerCase(),
                      })),
                    ['sorttext'],
                    ['asc']
                  )}
                  onChange={(_, val) => {
                    setValue(val.value);
                    return val.value;
                  }}
                  selectOnBlur={false}
                />
              );
            },
            beginDt: ({ value, setValue }) => {
              return (
                <span className={styles.dateRange}>
                  <ATMDatePicker
                    type="range"
                    size="small"
                    format="MM/DD/YYYY"
                    pointing="right"
                    placeholder="Begin Date Range"
                    value={
                      value && [
                        Moment(value[0]).startOf('day').toDate(),
                        Moment(value[1]).endOf('day').toDate(),
                      ]
                    }
                    onChange={(_, val) => {
                      if (val.value === null) {
                        setValue(undefined);
                      } else if (
                        val.value &&
                        (val.value as Date[]).length === 2
                      ) {
                        const [start, end] = val.value as Date[];
                        const rangeValue = [
                          Moment(start).startOf('day').toDate(),
                          Moment(end).endOf('day').toDate(),
                        ];
                        setValue(rangeValue);
                      }
                      return val.value;
                    }}
                  />
                </span>
              );
            },
            buttons: () =>
              (+currentTab === TroubleTicketTab.Scheduled &&
                troubleTicketState.listScheduled.length === 0) ||
              (+currentTab === TroubleTicketTab.Trouble &&
                troubleTicketState.list.length === 0) ? (
                <ATMPopover
                  trigger={
                    <ATMButton
                      key="download"
                      secondary
                      size="tiny"
                      icon="download"
                    />
                  }
                  content="No Data to Download"
                  position="top left"
                />
              ) : (
                <ATMPopover
                  position="bottom left"
                  content="Download"
                  on="hover"
                  trigger={
                    <ATMButton
                      key="download"
                      secondary
                      size="tiny"
                      icon="download"
                      loading={status.fetching}
                      onClick={() => {
                        handleDownload();
                      }}
                    />
                  }
                />
              ),
          },
          filters: ({ values, setValue }) => {
            return (
              <TroubleTicketListSearchFilter
                lineSubstation={lineSubstation}
                setIsLineSubstation={setIsLineSubstation}
                setValue={setValue}
                setIsFirstLoad={() => false}
                values={values}
                counter={counter}
              />
            );
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default TroubleTicketListTables;
