/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { get, sortBy } from 'lodash';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMLoader,
  ATMPopover,
  ATMSegment,
  ATMTable,
} from 'shared-it-appmod-ui';
import {
  AccessRole,
  LERRequestFormStep,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import Moment, {
  format24hDateTime,
  format24hTime,
  formatDate,
} from 'src/libraries/moment.library';
import { ILerRequest } from 'src/models/ler-request.model';
import {
  compareValues,
  getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import LerRequestApprove from '../ler-request-approve/ler-request-approve.component';
import LerRequestReject from '../ler-request-reject/ler-request-reject.component';
import styles from './ler-request-pending-request.module.scss';
import { checkDateTimeDiff } from '../ler-request-version-control/ler-request-version-control-detail.component';

type IProp = {
  fields: Array<string>;
  data?: ILerRequest[];
  info?: string;
};

const DisplayValues: React.FC<IProp> = ({ fields, data, info }) => {
  if (!info) {
    return (
      <>
        {data?.map((val, index) => {
          return (
            <ATMTable.Cell key={`${index}FI`} verticalAlign="top" width="6">
              <p style={{ visibility: 'hidden' }}>
                <b>Hidden Field</b>{' '}
              </p>
              {data &&
                data.length > 0 &&
                fields.map((field, index2) => {
                  return (
                    <p
                      key={`${index}_${index2}`}
                      style={
                        index !== 0 && !Array.isArray(data[index][field])
                          ? compareValues([
                              get(data[index], field),
                              get(data[index - 1], field),
                            ]) === false
                            ? { backgroundColor: 'red', color: 'white' }
                            : {}
                          : index !== 0 && Array.isArray(data[index][field])
                          ? compareValues([
                              sortBy(data[index][field])
                                .map((v) => {
                                  return typeof v[
                                    Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                  ] === 'object'
                                    ? `${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS
                                            .category
                                        ].shortDescCat
                                      } - ${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                        ].shortDesc
                                      }`
                                    : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                                })
                                .toString(),
                              sortBy(data[index - 1][field])
                                .map((v) => {
                                  return typeof v[
                                    Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                  ] === 'object'
                                    ? `${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS
                                            .category
                                        ].shortDescCat
                                      } - ${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                        ].shortDesc
                                      }`
                                    : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                                })
                                .toString(),
                            ]) === false
                            ? { backgroundColor: 'red', color: 'white' }
                            : {}
                          : {}
                      }
                    >
                      {' '}
                      {!Array.isArray(data[index][field])
                        ? field === 'requestStat'
                          ? Lang.LER_REQUEST_STATUS[
                              get(data[index], field) ?? 12
                            ]
                          : get(data[index], field) || '-'
                        : val[field]
                            ?.map((v) => {
                              return typeof v[
                                Lang.LER_VERSION_DISPLAY_FIELDS[field]
                              ] === 'object'
                                ? `${
                                    v[Lang.LER_VERSION_DISPLAY_FIELDS.category]
                                      .shortDescCat
                                  } - ${
                                    v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                      .shortDesc
                                  }`
                                : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                            })
                            .toString() || '-'}{' '}
                    </p>
                  );
                })}
            </ATMTable.Cell>
          );
        })}
      </>
    );
  }
  return (
    <>
      {data?.map((val, index) => {
        return (
          <ATMTable.Cell key={`${index}FI`} verticalAlign="top" width="3">
            <p style={{ visibility: 'hidden' }}>
              <b>Hidden Field</b>{' '}
            </p>
            {data &&
              data.length > 0 &&
              fields.map((field, index2) => {
                return (
                  <p
                    key={`${index}_${index2}`}
                    style={
                      index !== 0 && !Array.isArray(data[index][info][field])
                        ? compareValues([
                            get(data[index], `${info}.${field}`),
                            get(data[index - 1], `${info}.${field}`),
                          ]) === false
                          ? { backgroundColor: 'red', color: 'white' }
                          : {}
                        : index !== 0 && Array.isArray(data[index][info][field])
                        ? compareValues([
                            sortBy(data[index][info][field])
                              .map((v) => {
                                return typeof v[
                                  Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                ] === 'object'
                                  ? `${
                                      v[
                                        Lang.LER_VERSION_DISPLAY_FIELDS.category
                                      ].shortDescCat
                                    } - ${
                                      v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                        .shortDesc
                                    }`
                                  : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                              })
                              .toString(),
                            sortBy(data[index - 1][info][field])
                              .map((v) => {
                                return typeof v[
                                  Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                ] === 'object'
                                  ? `${
                                      v[
                                        Lang.LER_VERSION_DISPLAY_FIELDS.category
                                      ].shortDescCat
                                    } - ${
                                      v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                        .shortDesc
                                    }`
                                  : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                              })
                              .toString(),
                          ]) === false
                          ? { backgroundColor: 'red', color: 'white' }
                          : {}
                        : {}
                    }
                  >
                    {' '}
                    {!Array.isArray(data[index][info][field])
                      ? field.includes('.firstName')
                        ? get(data[index][info], field)
                          ? `${get(data[index][info], field)} ${get(
                              data[index][info],
                              `${field.replace('.firstName', '.lastName')}`
                            )}`
                          : '-'
                        : typeof data[index][info][field] === 'boolean' ||
                          field.includes('authorized')
                        ? data[index][info][field]
                          ? Lang.LBL_YES
                          : Lang.LBL_NO
                        : get(data[index][info], field) || '-'
                      : val[info][field]
                          ?.map((v) => {
                            return typeof v[
                              Lang.LER_VERSION_DISPLAY_FIELDS[field]
                            ] === 'object'
                              ? `${
                                  v[Lang.LER_VERSION_DISPLAY_FIELDS.category]
                                    .shortDescCat
                                } - ${
                                  v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                    .shortDesc
                                }`
                              : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                          })
                          .toString() || '-'}{' '}
                  </p>
                );
              })}
          </ATMTable.Cell>
        );
      })}
    </>
  );
};

type IProps = {
  data: ILerRequest;
  // handleApprove?: () => void;
  setClose: () => void;
};

const LerRequestPendingRequest: React.FC<IProps> = ({
  data,
  // handleApprove,
  setClose,
}) => {
  const { state, actions } = useLerRequestContext();
  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CHANGE_REQUEST_READ
  );
  useEffect(() => {
    // if (
    //   !status.fetching &&
    //   (!state.changeRequest ||
    //     (state.changeRequest &&
    //       state.changeRequest.requestId !== data.requestId))
    // ) {
    if (!status.fetching) {
      actions.changeRequestGET(data.requestId);
    }
  }, []);

  const isAllowedToViewISO = hasRole(AccessRole.MCC_OUTAGE_SCHEDULER);

  return (
    <ATMSegment className={styles.content}>
      <ATMGrid>
        {status.fetching ? (
          <ATMGrid.Row columns={1}>
            <ATMLoader inline="centered" active />
          </ATMGrid.Row>
        ) : (
          <>
            <ATMGrid.Row columns={2}>
              <ATMGrid.Column verticalAlign="middle">
                <ATMHeader as="h2">{Lang.TTL_CHANGE_REQUEST_REVIEW}</ATMHeader>
              </ATMGrid.Column>
              <ATMGrid.Column textAlign="right" verticalAlign="middle">
                {hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) ? (
                  <>
                    {data.requestStat === LERRequestStatus.Submitted ? (
                      <ATMPopover
                        trigger={
                          <ATMButton
                            style={{ color: '#666' }}
                            type="button"
                            content={Lang.LBL_CHANGE_REQUEST_REJECT}
                          />
                        }
                        content={Lang.MSG_CHANGE_REQUEST_APPROVE_NOTE}
                        basic
                        position="bottom center"
                      />
                    ) : (
                      <LerRequestReject
                        data={data}
                        trigger={
                          <ATMButton
                            secondary
                            negative
                            type="button"
                            content={Lang.LBL_CHANGE_REQUEST_REJECT}
                          />
                        }
                        isChangeRequest
                        handleSuccess={() => {
                          setClose();
                        }}
                      />
                    )}
                    {data.requestStat === LERRequestStatus.Submitted ? (
                      <ATMPopover
                        trigger={
                          <ATMButton
                            type="button"
                            content={Lang.LBL_CHANGE_REQUEST_APPROVE}
                            style={{ color: '#666' }}
                          />
                        }
                        content={Lang.MSG_CHANGE_REQUEST_APPROVE_NOTE}
                        basic
                        position="bottom center"
                      />
                    ) : (
                      <LerRequestApprove
                        data={data}
                        trigger={
                          <ATMButton
                            color="green"
                            type="button"
                            content={Lang.LBL_CHANGE_REQUEST_APPROVE}
                          />
                        }
                        // handlePostApproval={handleApprove}
                        isChangeRequest
                      />
                    )}
                  </>
                ) : (
                  <>
                    <LerRequestReject
                      data={data}
                      trigger={
                        <ATMButton
                          secondary
                          negative
                          type="button"
                          content={Lang.LBL_CHANGE_REQUEST_REJECT}
                          disabled={
                            hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
                              ? data.requestStat === LERRequestStatus.Submitted
                              : false
                          }
                        />
                      }
                      isChangeRequest
                      handleSuccess={() => {
                        setClose();
                      }}
                    />
                    <LerRequestApprove
                      data={data}
                      trigger={
                        <ATMButton
                          color="green"
                          type="button"
                          content={Lang.LBL_CHANGE_REQUEST_APPROVE}
                        />
                      }
                      isChangeRequest
                    />
                  </>
                )}
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column width={12}>
                <ATMTable celled>
                  <ATMTable.Header>
                    <ATMTable.Row>
                      <ATMTable.HeaderCell width={4}>
                        {Lang.LBL_CHANGE_REQUEST_FIELD}
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell width={6}>
                        {Lang.formatString(
                          Lang.LBL_CHANGE_REQUEST_VERSION,
                          data.version_no ?? '0'
                        )}
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell width={6}>
                        {Lang.LBL_CHANGE_REQUEST}
                      </ATMTable.HeaderCell>
                    </ATMTable.Row>
                  </ATMTable.Header>
                  <ATMTable.Body>
                    {/* Facility Information */}
                    {state.changeRequest?.outageFacility && (
                      <ATMTable.Row>
                        <ATMTable.Cell textAlign="right">
                          <p>
                            <b>{Lang.LBL_FACILITY_INFORMATION}</b>{' '}
                          </p>
                          <p>{Lang.LBL_OUTAGE_TYPE}</p>
                          <p>{Lang.LBL_FACILITY_NAME}</p>
                          <p>{Lang.LBL_EQUIPMENT_TYPE}</p>
                          <p>{Lang.LBL_SUBSTATION}</p>
                          <p>{Lang.LBL_VOLTAGE}</p>
                        </ATMTable.Cell>
                        <DisplayValues
                          fields={
                            Lang.LER_VERSION_DISPLAY_FIELDS[
                              LERRequestFormStep.OUTAGE_FACILITY
                            ]
                          }
                          data={[data, state.changeRequest]}
                          info={LERRequestFormStep.OUTAGE_FACILITY}
                        />
                      </ATMTable.Row>
                    )}
                    {/* Job Information */}
                    {state.changeRequest?.jobInformation && (
                      <ATMTable.Row>
                        <ATMTable.Cell textAlign="right">
                          <p>
                            <b>{Lang.TTL_JOB_INFO}</b>{' '}
                          </p>
                          <p>{Lang.LBL_SWITCH_IN}</p>
                          <p>{Lang.LBL_SWITCH_OUT}</p>
                          <p>{Lang.LBL_SHORT_DESCRIPTION}</p>
                          <p>{Lang.LBL_RECALL_TIME}</p>
                          <p>{Lang.LBL_JOB_INFO_JOB_ID}</p>
                          <p>{Lang.LBL_WORK_ORDER_NUMBER}</p>
                          <p>{Lang.LBL_WORK_DESC}</p>
                          <p>{Lang.LBL_JOB_LOC}</p>
                          <p>{Lang.LBL_NOTES}</p>
                          <p>{Lang.LBL_RADIALIZED_SUBS}</p>
                          <p>{Lang.LBL_RIMS_PROJECT_PHASE}</p>
                          <p>{Lang.LBL_TB_COORDINATE}</p>
                          <p>{Lang.LBL_GROUND_STATEMENT}</p>
                          <p>{Lang.LBL_TEST_VOLTAGE}</p>
                        </ATMTable.Cell>
                        <DisplayValues
                          fields={
                            Lang.LER_VERSION_DISPLAY_FIELDS[
                              LERRequestFormStep.JOB_INFORMATION
                            ]
                          }
                          data={[data, state.changeRequest]}
                          info={LERRequestFormStep.JOB_INFORMATION}
                        />
                      </ATMTable.Row>
                    )}
                    {/* Crew Information */}
                    {state.changeRequest?.crewInformation && (
                      <ATMTable.Row>
                        <ATMTable.Cell textAlign="right">
                          <p>
                            <b>{Lang.TTL_CREW_INFO}</b>{' '}
                          </p>
                          <p>{Lang.LBL_CREW_INFO_NAME}</p>
                          <p>{Lang.LBL_INFO_CELLPHONE_NO}</p>
                          <p>{Lang.LBL_CREW_INFO_PAGER}</p>
                          <p>{Lang.LBL_CREW_INFO_TRUCK}</p>
                          <p>{Lang.LBL_OUTAGE_REQUESTOR_NAME}</p>
                          <p>{Lang.LBL_REQUESTOR_EMAIL}</p>
                          <p>{Lang.LBL_AUTHORIZED}</p>
                          <p>{Lang.LBL_CONTACT_NUMBER}</p>
                          <p>{Lang.LBL_REQUESTOR_SUPERVISOR}</p>
                        </ATMTable.Cell>
                        <DisplayValues
                          fields={
                            Lang.LER_VERSION_DISPLAY_FIELDS[
                              LERRequestFormStep.CREW_INFORMATION
                            ]
                          }
                          data={[data, state.changeRequest]}
                          info={LERRequestFormStep.CREW_INFORMATION}
                        />
                      </ATMTable.Row>
                    )}
                    {/* Auth Statement */}
                    {state.changeRequest?.authorizationStatement && (
                      <ATMTable.Row
                        style={!isAllowedToViewISO ? { display: 'none' } : {}}
                      >
                        <ATMTable.Cell textAlign="right">
                          <p>
                            <b>{Lang.TTL_AUTHORIZATION_INFORMATION}</b>{' '}
                          </p>
                          <p>{Lang.LBL_OPERATING_NOTE}</p>
                          <p>{Lang.LBL_CANCELLATION_NOTE}</p>
                          <p>{Lang.LBL_DAILY_OUTAGE_REPORT_NOTES}</p>
                        </ATMTable.Cell>
                        <DisplayValues
                          fields={
                            Lang.LER_VERSION_DISPLAY_FIELDS[
                              LERRequestFormStep.AUTHORIZATION_STATEMENT
                            ]
                          }
                          data={[data, state.changeRequest]}
                          info={LERRequestFormStep.AUTHORIZATION_STATEMENT}
                        />
                      </ATMTable.Row>
                    )}
                    {/* Outage Date */}
                    {state.changeRequest &&
                      state.changeRequest[LERRequestFormStep.OUTAGE_DATE] && (
                        <ATMTable.Row>
                          <ATMTable.Cell textAlign="right" verticalAlign="top">
                            <b>{Lang.TTL_OUTAGE_DATES}</b>
                          </ATMTable.Cell>
                          <ATMTable.Cell verticalAlign="top">
                            {data[LERRequestFormStep.OUTAGE_DATE].length >
                              0 && (
                              <ATMGrid columns={3}>
                                <ATMGrid.Row>
                                  <ATMGrid.Column>
                                    <b>{Lang.LBL_DATE}</b>
                                  </ATMGrid.Column>
                                  <ATMGrid.Column>
                                    <b>{Lang.LBL_STARTS}</b>
                                  </ATMGrid.Column>
                                  <ATMGrid.Column>
                                    <b>{Lang.LBL_ENDS}</b>
                                  </ATMGrid.Column>
                                </ATMGrid.Row>
                                {data[LERRequestFormStep.OUTAGE_DATE]?.map(
                                  (item, i) => {
                                    return (
                                      <ATMGrid.Row key={i}>
                                        <ATMGrid.Column>
                                          {formatDate(item.startTm)}
                                        </ATMGrid.Column>
                                        <ATMGrid.Column>
                                          {format24hTime(item.startTm)}
                                        </ATMGrid.Column>
                                        <ATMGrid.Column>
                                          {Moment(item.startTm).isSame(
                                            Moment(item.stopTm),
                                            'date'
                                          )
                                            ? format24hTime(item.stopTm)
                                            : format24hDateTime(item.stopTm)}
                                        </ATMGrid.Column>
                                      </ATMGrid.Row>
                                    );
                                  }
                                )}
                              </ATMGrid>
                            )}
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            {state.changeRequest &&
                              state.changeRequest[
                                LERRequestFormStep.OUTAGE_DATE
                              ] && (
                                <ATMGrid columns={3}>
                                  <ATMGrid.Row>
                                    <ATMGrid.Column>
                                      <b>{Lang.LBL_DATE}</b>
                                    </ATMGrid.Column>
                                    <ATMGrid.Column>
                                      <b>{Lang.LBL_STARTS}</b>
                                    </ATMGrid.Column>
                                    <ATMGrid.Column>
                                      <b>{Lang.LBL_ENDS}</b>
                                    </ATMGrid.Column>
                                  </ATMGrid.Row>
                                  {state.changeRequest[
                                    LERRequestFormStep.OUTAGE_DATE
                                  ]?.map((item, i) => {
                                    return (
                                      <ATMGrid.Row key={i}>
                                        <ATMGrid.Column
                                          style={
                                            data[LERRequestFormStep.OUTAGE_DATE]?.length
                                              ? checkDateTimeDiff(
                                                { data: data?.outageDates,
                                                  startDate: item?.startTm as any })
                                                ? {
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                  }
                                                : {}
                                              : {}
                                          }
                                        >
                                          {formatDate(item.startTm)}
                                        </ATMGrid.Column>
                                        <ATMGrid.Column
                                          style={
                                            data[LERRequestFormStep.OUTAGE_DATE]?.length
                                              ? checkDateTimeDiff(
                                                { data: data.outageDates, startDate: item?.startTm as any, isStartTime: true })
                                                ? {
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                  }
                                                : {}
                                              : {}
                                          }
                                        >
                                          {format24hTime(item.startTm)}
                                        </ATMGrid.Column>
                                        <ATMGrid.Column
                                          style={
                                            data[LERRequestFormStep.OUTAGE_DATE]?.length
                                              ? checkDateTimeDiff({ data: data.outageDates, startDate: item?.startTm as any, endDate: item?.stopTm as any, isStopTime: true })
                                                ? {
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                  }
                                                : {}
                                              : {}
                                          }
                                        >
                                          {Moment(item.startTm).isSame(
                                            Moment(item.stopTm),
                                            'date'
                                          )
                                            ? format24hTime(item.stopTm)
                                            : format24hDateTime(item.stopTm)}
                                        </ATMGrid.Column>
                                      </ATMGrid.Row>
                                    );
                                  })}
                                </ATMGrid>
                              )}
                          </ATMTable.Cell>
                        </ATMTable.Row>
                      )}
                    {/* Other Details  */}
                    {state.changeRequest && (
                      <ATMTable.Row>
                        <ATMTable.Cell textAlign="right">
                          <p>
                            <b>{Lang.TTL_OTHER_DETAILS}</b>{' '}
                          </p>
                          <p>{Lang.LBL_CANCEL_TYPE}</p>
                          <p>{Lang.LBL_CANCEL_BY}</p>
                          <p>{Lang.LBL_REASON_OF_REJECTION}</p>
                          <p>{Lang.LBL_REJECTED_BY}</p>
                          <p>{Lang.LBL_REASON_OF_UPDATE}</p>
                          <p>{Lang.LBL_UPDATED_BY}</p>
                          {/* US #605811 10/02/2023 Date Modified on Version History */}
                          <p>{Lang.LBL_DATE_MODIFIED}</p>
                          <p>{Lang.LBL_STATUS}</p>
                          <p>{` Change Request ${Lang.LBL_STATUS}`}</p>
                        </ATMTable.Cell>
                        <DisplayValues
                          fields={Lang.LER_VERSION_DISPLAY_FIELDS.otherDetails}
                          data={[data, state.changeRequest]}
                        />
                      </ATMTable.Row>
                    )}
                  </ATMTable.Body>
                </ATMTable>
              </ATMGrid.Column>
            </ATMGrid.Row>
          </>
        )}
      </ATMGrid>
    </ATMSegment>
  );
};

export default LerRequestPendingRequest;
